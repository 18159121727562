@import '../../assets/styles/variables.scss';

.calendar-today{
    .today-header{
        position: relative;
        display: flex;
        background-color: #d9d9d9;
        border: #CCC 1px solid;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 16px;
        align-items: center;
        justify-content: center;

        .day-title{
            text-align: center;
            flex-grow: 1;
        }

        .btn-wrapper {
            width: auto;
        }
        
        .day{
            position: relative;
            display: inline-flex;
            background-color: $primary;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            color: #FFF;
            margin-right: 16px;
        }

        .weekday{
            font-size: 20px;
            font-weight: 600;
        }
    }

    .today-body{
        position: relative;
        display: flex;

        .now {
            position: absolute;
            width: calc(100% - 55px);
            height: 1px;
            background-color: $secondary;
            left: 55px;
            z-index: 3;

            span{
                position: absolute;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $secondary;
                top: -4.5px;
                left: 0;
            }
        }

        .timeline{
            position: relative;
            display: block;
            width: 70px;
        }

        .today{
            flex-grow: 1;
        }

        .time {
            position: relative;
            display: block;
            width: 100%;
            border-bottom: #f2f2f2 1px solid;
            border-right: #f2f2f2 1px solid;
            font-size: 12px;
            padding: 4px;
            color: #666;
            height: 60px;
            box-sizing: border-box;
            text-align: center;
        }

        .line {
            position: relative;
            display: block;
            width: 100%;
            height: 60px;
            border-bottom: #f2f2f2 1px solid;
            border-right: #f2f2f2 1px solid;
            box-sizing: border-box;

            .subline {
                position: relative;
                display: block;
                width: 100%;
                height: 30px;
                border-bottom: #f2f2f2 1px dotted;
            }
        }

        .events {
            position: absolute;
            display: block;
            width: calc(100% - 70px);
            z-index: 2;

            .event {
                position: absolute;
                display: flex;
                left: 0;
                width: calc(100% - 16px);
                margin: 0 8px;
                border: #FFF 1px solid;
                box-sizing: border-box;
                padding: 8px;
                font-size: 10px;
                border-radius: 6px;
                min-height: 30px;
                align-items: center;
                justify-content: flex-start;
                color: #FFF;
                cursor: pointer;

                &.open{
                    background-color: #f2f2f2;
                    border-color: #fff;
                    color: #f2f2f2;

                    &:hover{
                        background-color: #666;
                        color: #fff;
                    }
                }

                &.busy{
                    background-color: $primary-dark;
                    color: #fff;

                    &:hover{
                        background-color: $primary;
                    }
                }

                &.past{
                    background-color: #f2f2f2;
                    border-color: transparent;
                    color: #999;
                    text-decoration: line-through;

                    &:hover{
                        background-color: #f2f2f2;
                        border-color: transparent;
                        color: #666;
                        
                    }
                }

                .event-time{
                    font-size: 14px;
                    font-weight: bold;
                    width: 40px;
                    display: block;
                }

                .event-title{
                    font-size: 14px;
                    font-weight: normal;
                    display: block;
                    width: calc(100% - 40px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                
            }

        }
    }
}