@import "./variables.scss";
html,
body,
#root {
  width: 100%;
  height: 100%;
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site{
  &.bg-gray{
    background-color: #f2f2f2;
  }
  &.bg-white{
    background-color: #fff;
  }
  &.scroll-lock {
    height: 100vh;
    overflow: hidden;
  }
}

p {
  font-size: 16px;
}

a {
  color: $primary-darkest;
  text-decoration: none;

  &.dark,
  &.dark:visited{
    color: $primary-light;

    &:hover,
    &:active{
      color: $primary;
    }
  }

  &:hover {
    color: $primary-dark;
    text-decoration: underline;
  }
  &:active {
    color: $primary-light;
    text-decoration: none;
  }
  &:visited {
    color:  $primary-darkest;
    text-decoration: none;
  }
}

/* Containers */
.container {
  position: relative;
  display: block;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  box-sizing: border-box;

  &.full {
    max-width: 100%;
  }

  &.compact {
    max-width: 720px;
  }
}

.border-bottom{
  border-bottom: 1px solid #CCC;
}
.border-top{
  border-top: 1px solid #CCC;
}

/* Text alignment */
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.justify {
  text-align: justify;
}

/* MARGINS */
.margin-y--none{
  margin-top: 0;
  margin-bottom: 0;
}

.margin-top--none{
  margin-top: 0;
}

.margin--sm {
  margin: 15px;
}
.margin-sides--sm {
  margin-left: 15px;
  margin-right: 15px;
}
.margin-top--sm {
  margin-top: 15px;
}
.margin-bottom--xs {
  margin-bottom: 8px;
}
.margin-bottom--sm {
  margin-bottom: 15px !important;
}
.margin-bottom--md {
  margin-bottom: 30px !important;
}
.margin-bottom--lg {
  margin-bottom: 48px !important;
}
.margin-top--md {
  margin-top: 30px;
}

/* PADDING */
.padding-sm{
  padding: 8px;
}
.padding-md{
  padding: 16px;
}

/* Material UI error messages on inputs */
.MuiFormHelperText-root {
  font-size: 12px !important;
}

/* FLEX */
.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}

/* Buttons */
.btn-wrapper {
  position: relative;
  width: 100%;
  display: flex;

  &.on-right {
    justify-content: flex-end;
  }

  &.on-left {
    justify-content: flex-start;
  }

  &.centered {
    justify-content: center;
  }

  &.inverted-in-mobile {
    flex-direction: column-reverse;

    button {
      margin: 0;
      margin-bottom: 15px;
    }
  }

  &.side-by-side{
    .MuiButtonBase-root{
      margin-right: 16px;

      &:last-of-type{
        margin-right: 0;
      }
    }

    .MuiButtonGroup-root{
      margin: 10px 16px 10px 10px;

      &:last-child{
        margin-right: 0;
      }

    }

    .MuiButtonGroup-root .MuiButtonBase-root{
      margin: 0;
    }

  }

  &.at-bottom {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    bottom: 0;
    left: 0;
  }

  .MuiIconButton-root {
    width: 50px;
    height: 50px;
  }
  
  .MuiButton-outlined.btn-ghost {
    border: #CCC 1px solid;
    color: #CCC;

    &:hover{
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .MuiButton-contained.btn-ghost {
    border: #999 1px solid;
    background-color: #999;
    color: #fff;

    &:hover{
      border: #666 1px solid;
      background-color: #666;
    }
  }

  .btn-circle {
    position: relative;
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;

    &.rotate-90 {
      transform: rotate(90deg);
    }

    &:only-child {
      margin: 0;
    }

    &.small {
      width: 25px;
      height: 25px;

      .MuiSvgIcon-root,
      .material-icons {
        font-size: 20px;
      }
    }

    &.medium {
      width: 40px;
      height: 40px;

      .MuiSvgIcon-root {
        font-size: 24px;
      }
    }

    .MuiSvgIcon-root {
      font-size: 32px;
    }

    &:disabled {
      background-color: #e2e2e2 !important;
      border: #e2e2e2 2px solid !important;
      color: #ccc !important;
      cursor: not-allowed;
    }

    &.primary {
      background-color: #4caf50;
      border: #4caf50 2px solid;
      color: #fff;

      &:hover {
        background-color: rgb(53, 122, 56);
        border-color: rgb(53, 122, 56);
      }
    }

    &.other {
      background-color: #999;
      border: #999 2px solid;
      color: #fff;

      &:hover {
        background-color: #666;
        border-color: #666;
      }
    }

    &.attention {
      background-color: #f44336;
      border: #f44336 2px solid;
      color: #fff;

      &:hover {
        background-color: #d32f2f;
        border-color: #d32f2f;
      }
    }

    &.ghost {
      background-color: transparent;
      border: none;
      color: #e2e2e2;

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
      }
    }

    &.ghost-dark {
      background-color: transparent;
      border: none;
      color: #666;

      &:hover {
        background-color: #666;
        color: #fff;
      }
    }
  }

  .btn-icon-right {
    .MuiSvgIcon-root {
      margin-left: 10px;
    }
  }
}

/* TABLES */

table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: 30px;
  font-size: 14px;

  th {
    border-bottom: #ccc 2px solid;
    height: 50px;
    vertical-align: bottom;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 16px;
  }

  td {
    height: 50px;
    border-bottom: #ccc 1px solid;

    &.delete {
      padding-right: 10px;
      color: #666;
    }
  }

  tr {
    background-color: #fff;
  }
  tr:hover td {
    background-color: #f2f2f2;
  }

  td{
    color: #666;
  }
  .cell-xxxs {
    width: 10px;
  }
  .cell-xxs {
    width: 20px;
  }
  .cell-xs {
    width: 40px;
  }
  .cell-sm {
    width: 75px;
  }
  .cell-md {
    width: 120px;
  }
  .cell-l {
    width: 150px;
  }
  .cell-xl {
    width: 200px;
  }
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

/* UI MODIFIERS */

.MuiButton-root {
  padding: 8px 16px !important;
}

/* PLACEHOLDERS */
.placeholder {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  transition: all ease-in-out 0.2s;

  &.compact {
    max-width: 60%;
  }

  &.normal {
    max-width: 1100px;
  }

  &.full {
    max-width: 100%;
  }
}

/* RICHTEXT */ 
.richtext{
  ol, ul{
      li{
          margin-bottom: 16px;
      }
  }

  .img{
      margin: 5px;

      &.horizontal{
          width: 100%;
          height: auto;

          &.icon{
              max-width: 30px;
          }
          &.small{
              max-width: 150px;
          }
          &.medium{
              max-width: 300px;
          }
          &.large{
              max-width: 600px;
          }
          &.module{
              max-width: 100%;
          }
      }

      &.vertical{
          height: 100%;
          width: auto;

          &.icon{
              max-height: 30px;
          }
          &.small{
              max-height: 150px;
          }
          &.medium{
              max-height: 300px;
          }
          &.large{
              max-height: 600px;
          }
          &.module{
              max-height: 100%;
          }
      }

      &.inline{
          position: relative;
          display: inline-block;
      }

      &.left{
          float: left;
      }

      &.right{
          float: right;
      }
      &.box{
          position: relative;
          display: block;
      }


  }
}

.hide-in-mobile{
  display: revert;
}

.hide-in-desktop{
  display: none;
}

.module-title-bar{
  position: relative;
  display: block;
  background-color: #666;
  padding: 8px 16px;
  color: #FFF;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .btn-wrapper {
    &.side-by-side {
      button {
        margin: 10px;
      }
      button:first-of-type {
        margin-left: 0;
      }
      button:last-of-type {
        margin-right: 0;
      }
    }

    &.inverted-in-mobile {
      flex-direction: row;

      button {
        margin: 10px;
      }
    }
  }
}

.svg-icon {
  fill: #2c2c2c;
  width: 22px;
  height: 22px;
}

@media only screen and (max-width: 767px) {

.hide-in-mobile{
  display: none !important;
}

.hide-in-desktop{
  display: revert;
}

.btn-wrapper{
  &.side-by-side{
    flex-direction: column;

    .MuiButtonBase-root{
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}

  /* Containers */
.container {
  max-width: calc(100% - 32px);

    &.full {
      max-width: 100%;
    }

    &.compact {
      max-width: calc(100% -  64px);
    }

    .module__expand-collapse{
      padding: 0;
    }
  }

}
