.form-coaching{
    .question{
            position: relative;
            display: block;
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            margin: 16px auto;
            background-color: #f2f2f2;
            border-radius: 6px;
    }

    .form-group{
        margin-bottom: 36px;
        .title{
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 16px;
        }
        .MuiGrid-container{
            border: #CCC 1px solid;
            border-radius: 4px;
            padding: 16px;

            .question:first-of-type{
                margin-top: 0;
            }
        }

        .other{
            position: relative;
            display: block;
            margin: 16px auto;

            &.hidden{
                display: none;
            }
        }

            
    }

    .MuiSlider-root{
        width: 75%;
        margin: 24px auto;
        display: block;
    }
}

@media only screen and (max-width: 767px) {
.form-coaching{
    .MuiSlider-root{
        width: 100%;
        margin: 24px auto;
        display: block;
    }
}
}