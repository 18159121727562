.image-selector{
    position: relative;
    display: block;
    width: 100%;

    .input{
        position: relative;
        display: block;
        text-align: center;
        width: 100%;
        height: 170px;

        .image-preview{
            position: relative;
            display: block;
            width: 200px;
            height: 150px;
            background-color: #f2f2f2;
            border: #CCC 1px solid;
            border-radius: 4px;
            margin: 0 auto;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
        }

        .info{
            position: relative;
            display: flex;
            box-sizing: border-box;
            padding: 8px;
            top: -30px;
            left: 0;
            width: 100%;
            height: auto;
            z-index: 3;
            .btn-wrapper{
                .btn-circle{
                    margin: 0 5px;
                }
            }
        }
        

    }

    

    .mobile-only{
        display: block;
    }

    .desktop-only{
        display: none;
    }
}

// Tablets and Desktop
@media screen and (min-width: 768px){
    .image-selector{
        .mobile-only{
            display: none;
        }
        .desktop-only{
            display: block;
        }
    }
}