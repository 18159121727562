@import "../../assets/styles/variables.scss";
.customer-schedule{

    .no-results {
        position: relative;
        display: block;
        border: #d6d6d6 1px solid;
        border-radius: 4px;
        box-shadow: #ccc 1px 1px 5px;
        background-color: #f2f2f2;
        box-sizing: border-box;
        padding: 16px;
        text-align: center;
        margin: 24px auto;

        span.material-icons{
            font-size: 36px;
            color: #fff;
            background-color: #CCC;
            border-radius: 50%;
            position: relative;
            display: inline-flex;
            width: 60px;
            height: 60px;
            align-items: center;
            justify-content: center;
        }

        h4{
            margin-bottom: 16px;
            margin-top: 8px;
            font-size: 18px;
        }
    }

    .btn.btn-delete{
        border: #666 1.5px solid;
        border-radius: 4px;
        color: #666;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        padding: 0 8px;
        cursor: pointer;
        margin-left: 16px;

        &:hover{
            border-color: #f32;
            background-color: #F32;
            color: #FFF;
        }
    }

    .notes{
        color: #999;
        font-size: 12px;
    }
    
    .event-card {
        position: relative;
        display: flex;
        width: 100%;
        border: #f2f2f2 1px solid;
        border-radius: 4px;
        padding: 4px;
        box-sizing: border-box;
        margin-bottom: 16px;
        align-items: center;
        transition: all ease-in-out 0.3s;

        &:hover{
            background-color: #f9f9f9;
            box-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
        }

        .loading{
            position: absolute;
            z-index: 3;
        }

        .card-header{
            position: relative;
            display: block;
            background-color: $primary-dark;
            border-radius: 4px;
            padding: 16px;
            color: #FFF;
            text-align: center;
            margin-right: 16px;

            .day{
                font-size: 24px;
                font-weight: bold;
            }
            .month{
                margin-bottom: 8px;
            }
            .time{
                font-size: 12px;
            }
        }
        .card-body{
            flex-grow: 1;
            position: relative;
            display: flex;
            align-items: center;

            .avatar{
                width: 60px;
                height: 60px;
                background-size: cover;
                border-radius: 50%;
                background-position: center center;
                margin-right: 16px;
            }

            .info{
                .id{
                    position: relative;
                    display: block;
                    margin-bottom: 8px;

                    span{
                        background-color: #999;
                        padding: 4px 8px;
                        border-radius: 4px;
                        color: #FFF;
                        margin-bottom: 8px;
                        position: relative;
                        font-size: 14px;
                    }
                }

                .name{
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 4px;

                    .company{
                        display: inline-block;
                        font-size: 16px;
                        color: #666;
                        margin-left: 8px;
                    }
                }

                .room{
                    font-size: 14px;
                    line-height: 16px;
                }

                
            }

        }
        .card-footer{
            padding: 16px;

        }
    }
}

@media only screen and (max-width: 767px) {
    .customer-schedule{
        .event-card{
            display: block;

            .card-header{
                display: flex;
                box-sizing: border-box;
                width: 100%;
                align-items: center;
                margin-bottom: 16px;

                .day{
                    margin-right: 8px;
                }
                .month{
                    margin-bottom: 0;
                    flex-grow: 1;
                    text-align: left;
                }
            }

            

            .card-body{
                display: block;
                text-align: center;
                margin-bottom: 16px;

                .avatar{
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 16px;
                }

                .info{
                    .name{
                        .company{
                            display: block;
                        }
                    }
                }
            }

            .card-footer{
                padding: 8px;

                .btn-wrapper{
                    .MuiButtonBase-root{
                        flex-grow: 1;
                    }
                }
            }
        }
    }
}