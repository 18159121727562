@import "../../assets/styles/variables.scss";
.search-field{
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    transition: all ease-in-out 0.5s;

    .btn-search{
        position: relative;
        display: block;
        margin-right: 16px;
    }

}
