.footer-menu{
    position: relative;
    display: block;
    width: 100%;

    .menu{
        list-style: none;
        margin: 0;
        padding: 0;

        .menu-item{
            position: relative;
            display: inline-block;
            margin-right: 10px;

            a{
                text-decoration: none;
                color: #fff;
                font-size: 14px;
            }
            a:hover{
                text-decoration: underline;
            }
        }
    }
}