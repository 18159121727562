@import "../../assets/styles/variables.scss";
.pages-directory{

    .icon-link{
        position: relative;
        color: inherit;
        text-decoration: none;
        background-color:rgba(0,0,0,0);
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.3s;

        &.alert{
            .MuiSvgIcon-root{
                fill: #FF0000;
            }
        }

        &:hover{
            background-color:rgba(0,0,0,0.05);
        }

        &.disabled{
            color: rgba(0,0,0,0.26);

            &:hover{
                background-color:rgba(0,0,0,0);
            }
        }
    }

    .alert-box{
        background-color: cornsilk;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        color: #555;
        margin-top: 50px;

        .material-icons{
            margin-right: 8px;
        }
    }
   
    .form-content-search {
        background-color: #f2f2f2;
        border: #CCC 1px solid;
        padding: 14px 14px 10px 14px;
        border-radius: 4px;

        .MuiOutlinedInput-root{
            background-color: #FFF;
        }
    }

    .toolbar{
        margin-bottom: 16px;
    }

    
    .results{
        position: relative;
        display: block;
        width: 100%;
        min-height: 100px;

        .results-loading{
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            z-index: 2;
            background-color:#FFF;
    
        }

        .results-list{
            .lock,
            .unlock{
                background-color: #CCC;
                position: relative;
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                margin: 0 auto;
            }
            .lock{
                background-color: #f00;
            }
            .unlock{
                background-color: $primary;
            }

            .title{
                display: flex;
                align-items: center;

                .text{
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .tag{
                    display: inline-flex;
                    align-items: center;
                    border-radius: 4px;
                    margin: 0 6px;
                    border: $secondary 1px solid;
                    color: $secondary;
                    background-color: transparent;
                    padding: 0px 12px 0px 0px;
                    font-size: 12px;

                    .MuiSvgIcon-root{
                        fill: $secondary;
                        width: 14px;
                        margin-left: 6px;
                    }
                }
            }

            .badge{
                position: relative;
                display: block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin: 0 auto;
                background-color: #CCC;
    
                &.primary{   
                    display: block;
                    background-color: $primary;
                }
        
                &.attention{
                    display: block;
                    background-color: $secondary-light;
                }
    
                &.alert{
                    display: block;
                    background-color: $secondary-dark;
                }
            }
        }

        .no-results{
            position: relative;
            display: flex;
            width: 100%;
            min-height: 300px;
            align-items: center;
            justify-content: center;
            color: #666;
            flex-direction: column;
            text-align: center;

            .MuiSvgIcon-root{
                font-size: 48px;
                color: #999;
            }
            
        }

        .hidden{
            display: none;
        }
    }
    

}
@media only screen and (max-width: 767px) {
    .pages-directory{
        
        .form-content-search{
            .MuiFormControl-root{
                margin-bottom: 8px;
            }
        }
    }
}