.highlighted-text-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .background-color{
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 16px;
        transition: all ease-in-out 0.1s;
        border: #fff 2px solid;
        cursor: pointer;

        &:hover{
            border-color: rgba(0,0,0,0.1);
        }

        &.selected{
            box-shadow: rgba(0,0,0,0.3) 0px 0px 8px;

            &:hover{
                border-color: #FFF;
            }
        }
    }

}
.module-highlighted-text{
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 24px;
    background-color: #f2f2f2;
    border: #CCC 1px solid;
    border-radius: 4px;
    box-sizing: border-box;

    .btn-wrapper.hidden{
        display: none;

        .hidden{
            display: none;
        }
    }
}

// Production
.content-page{
    .module-highlighted-text{
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {

    .module-highlighted-text{
        .btn-wrapper.side-by-side{
            flex-direction: column;
            width: 100%;

            .MuiButtonBase-root{
                display: block;
                width: 100%;
                margin-bottom: 16px;

                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }

}