.mdl{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    box-sizing: border-box;

    &.full{
        max-width: 100%;
    }
}

.container{
    width: 100%;
    max-width: 1170px;
}

@media only screen and (max-width: 1024px) {

    .mdl{
        max-width: calc(100% - 48px);
    }
    .container{
        max-width: calc(100% - 48px);
        .mdl{
            max-width: 100%;
        }
    }

}

@media only screen and (max-width: 767px) {

    .mdl{
        max-width: calc(100% - 48px);
    }

    .container{
        max-width: calc(100% - 48px);
        .mdl{
            max-width: 100%;
        }
    }

}