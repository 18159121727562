@import "../../assets/styles/variables.scss";

// Edit Mode
.banner-title-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 15px;
    border: #CCC 1px solid;
    border-radius: 4px;
    box-sizing: border-box;

    .module-banner-title{
        margin-top: 0;
        .banner{
            .banner-heading{
                align-items: center;
                justify-content: center;
                padding: 16px;
            }
        }
    }
}

// View Mode
.module-banner-title{
    position: relative;
    display: block;
    width: 100%;
    height: 450px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: -30px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%);

    &:after{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.3);
        z-index: 0;
        content: ' ';
    }

    .banner{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 10;

        
        .banner-heading{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 16px 16px 116px 16px;
            box-sizing: border-box;
            top: 0;
            left: 0;
            box-sizing: border-box;

            .category-name{
                position: relative;
                display: block;
                padding: 5px 10px;
                border-radius: 4px;
                background-color: $primary-dark;
                color: #fff;

                &.hidden{
                    display: none;
                }
            }
                
        }   
    }
}


// Production
.content-page{
    .module-banner-title{
        margin-top: 0;
        margin-bottom: 36px;
    }
}

@media only screen and (max-width: 767px) {
    .content-page,
    .contents{
        .module-banner-title{
            margin-bottom: 48px;
            clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
        }
    } 
}