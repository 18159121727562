.image-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .margin-top-mobile{
        margin-top: 0;
    }

    .ipt-image{
        min-height: 200px;
        margin-bottom: 32px;
    }
}
.module-image{
    position: relative;
    display: block;
    width: 100%;
    min-height: 50px;

    .image-wrapper{
        position: relative;
        display: block;
        width: 100%;

        &.centered{
            text-align: center;
            .avatar{
                margin: 0 auto;
            }
        }
        &.on-left{
            text-align: left;

            .avatar{
                margin: 0 auto 0 0;
            }
        }
        &.on-right{
            text-align: right;
            .avatar{
                margin: 0 0 0 auto;
            }
        }

        .hidden{
            display: none;
        }

        .avatar{
            position: relative;
            display: block;
            border-radius: 50%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            &.avatar-small{
                width: 150px;
                height: 150px;
            }

            &.avatar-large{
                width: 250px;
                height: 250px;
            }
            
            &.has-link{
                cursor: pointer;
            }
        }


        .image{
            max-width: 100%;
            background-color: #f2f2f2;
            
            &.horizontal{

                &.small{
                    width: 300px;
                    height: auto;
                }
                &.medium{
                    width: 500px;
                    height: auto;
                }
                &.large{
                    width: 800px;
                    height: auto;
                }
                &.module{
                    width: 100%;
                    height: auto;
                }
                &.avatar-small{
                    width: auto;
                    height: 150px;
                }

            }

            &.vertical{
                &.small{
                    width: auto;
                    height: 300px;
                }
                &.medium{
                    width: auto;
                    height: 500px;
                }
                &.large{
                    width: auto;
                    height: 800px;
                }
                &.module{
                    width: auto;
                    height: 100%;
                }
                &.avatar-small{
                    width: 150px;
                    height: auto;
                }


            }
        }

        .info{
            position: relative;
            display: block;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            border-top: #CCC 1px solid;
            margin-top: 2px;

            &.hidden{
                display: none;
            }

            .legend{
                position: relative;
                display: block;
                width: 100%;
                font-size: 14px;
                color: #666;
    
                &.hidden{
                    display: none;
                }
            }
            .source{
                position: relative;
                display: block;
                width: 100%;
                font-size: 12px;
                color: #999;
    
                &.hidden{
                    display: none;
                }
            }
        }

        
    }

    .info{
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {

    .image-edit-mode{
        .margin-top-mobile{
            margin-top: 32px;
        }
    }
}