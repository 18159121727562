@import '../../assets/styles/variables.scss';

.calendar-list{
    position: relative;
    display: block;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    .toolbar{
        position: relative;
        display: block;
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        margin-bottom: 24px;
        text-align: right;

        .MuiTypography-body1{
            font-size: 14px;
            color: #666;
        }
    }

    .day-header {
        position: relative;
        display: block;
        margin-bottom: 16px;

        .label-day {
            position: relative;
            display: inline-flex;
            background-color: $primary;
            color: #FFF;
            border-radius: 50%;
            font-size: 18px;
            margin-right: 8px;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
        }

        .label-weekday {
            font-size: 18px;
            font-weight: 600;
        }

    }

    .day-body{
        position: relative;
        display: block;
        margin-bottom: 24px;

        .event-card{
            position: relative;
            display: block;
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            background-color: #f2f2f2;
            border-radius: 4px;
            margin-bottom: 8px;
            border-left-style: solid;
            border-left-width: 8px;

            .schedule {
                position: relative;
                display: inline-block;
                border-radius: 4px;
                padding: 4px;
                font-size: 14px;
                color: #fff;
                min-width: 40px;
                text-align: center;
                margin-right: 16px;
            }

            .title{
                position: relative;
                display: inline-block;
                padding: 4px;
                font-size: 14px;
                color: #666;
            }

            &:hover{
                background-color: #FFF;
                box-shadow: #d6d6d6 0px 2px 5px;
            }

            &.past{
                border-left-color: #999;
                .schedule{
                    background-color: #CCC;
                }
                .title{
                    color: #999;
                    text-decoration: line-through;
                }
            }

            &.free{
                border-left-color: $primary;
                .schedule{
                    background-color: $primary;
                }
            }

            &.busy{
                border-left-color: $secondary;
                .schedule{
                    background-color: $secondary;
                }
            }

            &.hidden{
                display: none;
            }
        }
    }

    .legend{
        position: relative;
        display: flex;
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        background-color: #f2f2f2;
        border-radius: 4px;
        align-items: center;
        justify-content: flex-start;

        &.hidden{
            display: none;
        }
        

        div{
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            color: #666;
            font-size: 14px;
            margin-right: 16px; 
        }

        span{
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-right: 8px;
        }

        .free{
            span{
                background-color: $primary;
            }
        }

        .busy{
            span{
                background-color: $secondary;
            }
        }

        .past{
            span{
                background-color: #999;
            }
        }
    }

    .no-results{
        position: relative;
        display: block;
        text-align: center;
        font-size: 14px;
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 4px;
        border: #CCC 1px solid;
        padding: 24px;
        box-sizing: border-box;

        .title{
            font-size: 18px;
            font-weight: bold;
            color: #2c2c2c;
            margin-bottom: 8px;
        }

        .text{
            margin: 0 0 24px 0;
        }

        .MuiSvgIcon-root {
            display: block;
            width: 100%;
            font-size: 56px;
            color: #666;
        }
    }
}
