@import "../../assets/styles/variables.scss";
.youtube-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

}

.module__youtube {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;

    div{
        background-color: #f2f2f2;
    }
}

@media only screen and (max-width: 767px) {

    .module__youtube {
        div{
            max-width: 100%;
        }
    }

}