.directory-list{
    &.module__blog{
        .blog-items{
            .results{
                width: 100%;

                .post-card{
                    max-width: 100%;
                    width: 100%;
                    padding: 16px;

                    .info{
                        padding: 0;
                        .title{
                            margin: 0;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}