@import '../../assets/styles/variables.scss';
.comments{
    position: relative;
    display: block;
    width: 100%;
    margin: 30px auto;
    padding-bottom: 8px;
    border-bottom: #f2f2f2 4px solid;

    .row {
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    span.flag {
        margin-left: 6px;
        font-weight: normal;
        color: #fff;
        background-color: #CCC;
        position: relative;
        display: inline-block;
        padding: 2px 8px;
        border-radius: 4px;
    }

    .sharing-bar{
        position: relative;
        display: flex;
        width: 100%;
        border-bottom: #CCC 1px solid;
        margin-bottom: 8px;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;

        .title{
            flex-grow: 1;
            .heading-4{
                margin: 0;
            }
        }
        .options{
            position: relative;
            display: flex;
            align-items: center;
            width: auto;
        }
        
    }

    .no-user{
        position: relative;
        display: block;
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 4px;
        padding: 16px;
        color: #2c2c2c;
        box-sizing: border-box;
        margin-bottom: 16px;
    }

    .with-user{
        .ipt{
            width: 100%;
        }
        .btn-comment{
            width: 120px;
        }
    }

    .items{
        position: relative;
        display: block;
        width: 100%;
        padding: 0px 24px;
        box-sizing: border-box;

        &.hidden{
            display: none;
        }
        

        .item{
            position: relative;
            display: block;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 24px;

            &.checking{

                .comment{
                    .signature{
                        color: #999;
                    }
                    .text{
                        color: #999;
                    }
                    
                }

            }
            
            
                .avatar {
                    position: relative;
                    display: block;
                    width: 40px;
                    height: 40px;
                    flex-grow: 0;
                    border-radius: 50%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border: #d2d2d2 1px solid;
                    margin-right: 16px;
                }

                .comment{
                    
                    .signature{
                        position: relative;
                        display: block;
                        width: 100%;
                        color: $auxiliary-500;
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 4px;
                    }
                    .text{
                        position: relative;
                        display: block;
                        width: 100%;
                        color: $auxiliary-600;
                        font-weight: normal;
                        font-size: 14px;
                    }
                }

                .answer {
                    position: relative;
                    display: flex;
                    width: calc(100% - 48px);
                    box-sizing: border-box;
                    margin: 8px 0px 0px 48px;
                    padding: 16px;
                    border-radius: 6px;
                    background-color: #f2f2f2;
                }

                .checking-info {
                    position: relative;
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                    margin-bottom: 12px;

                    span{
                        border: #CCC 1px solid;
                        padding: 4px 8px;
                        border-radius: 4px;
                        color: #999;
                        font-size: 12px;
                    }
                }
           
        }
    }

    .more-items{
        text-align: center;

        &.hidden{
            display: none;
        }
    }
    
}
@media only screen and (max-width: 767px) {
    .comments{
        .sharing-bar{
            flex-direction: column-reverse;

            .title{
                display: none;
            }
        }
        .row{
            flex-wrap: nowrap;
        }
        .items{
            padding: 24px 0 0 0;

            .item{
                
                &.margin-bottom--sm{
                    margin-bottom: 32px !important;
                }
                

                .avatar{
                    flex-shrink: 0;
                }
                .answer{
                    margin: 0;
                    width: 100%;
                    margin-top: 16px;
                }
            }
        }
    }
}