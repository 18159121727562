@import "../../assets/styles/variables.scss";
.page-editor {
  padding-top: 80px;

  .loading{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: #f2f2f2;
  }

  .hidden{
    display: none;
  }

  .overlay{

    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 5;
    transition: all ease-in-out 0.2s;

    &.hidden{
      visibility: hidden;
      background-color: rgba(0,0,0,0.0);
    }

  }
  .page-update{
    position: fixed;
    display: block;
    width: 30%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: -100%;
    z-index: 10;
    padding: 150px 30px 30px 30px;
    background-color: #FFF;
    box-sizing: border-box;
    transition: all ease-in-out 0.2s;
    overflow: auto;

      .row{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 16px;

        .background-options{
          position: relative;
          display: block;
          width: 100%;
          
          .bg{
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            border: #e2e2e2 solid 4px;
            border-radius: 50%;
            margin-right: 10px;
            cursor: pointer;

            &:hover{
              border: #CCC solid 4px;
            }

            &.selected{
              border: $primary solid 4px;
            }

            &.bg-white{
              background-color: #FFF;
            }
  
            &.bg-gray{
              background-color: #f2f2f2;
            }

          }
          
        }

      }
      .label{
        margin-bottom: 8px;
        color: #2c2c2c;
        font-weight: bold;
      }
      .thumb{
        width: 100%;
      }
      .aditional-info{
        margin-bottom: 8px;
        position: relative;
        display: block;
        font-size: 14px;
        color: #999;
      }
      .keyword{
        position: relative;
        display: inline-block;
        padding: 8px 16px;
        border-radius: 4px;
        background-color: #f2f2f2;
        margin: 4px;
        color: #666;
        cursor: pointer;

        &:hover{
          background-color: #CCC;
          color: #fff;
        }

       
      }
      .category-button{
        border: #CCC 1px solid;
        border-radius: 4px;
        padding: 18.5px 14px;
        width: 100%;
        background-color: #FFF;
        text-align: left;
        cursor: pointer;

        &:hover{
          background-color: #f2f2f2;
        }

        .cat-parent,
        .cat-title,
        .cat-none{
          display: block;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .cat-parent{
          font-size: 12px;
          color: #999;
        }

        .cat-title{
          font-size: 16px;
          color: #666;
        }

        .cat-none{
          font-size: 16px;
          color: #CCC;
        }

      }


    &.opened{
      visibility: visible;
      left: 0;
    }

  }

  .page-tools {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    background-color: #e9e9e9;
    padding: 30px;
    margin-top: -40px;
    align-items: center;

    .back {
      position: relative;
      display: block;
      width: 100px;
    }
    .resume {
      position: relative;
      display: block;
      flex-grow: 1;

      .page-info {
        position: relative;
        display: block;
        margin-right: 30px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 6px;
        cursor: pointer;

        &:hover{
          background-color: rgba(0,0,0,0.1);
        }

        .category {
          position: relative;
          display: flex;
          margin-bottom: 8px;
          box-sizing: border-box;
          align-items: center;

          span {
            background-color: $secondary;
            padding: 5px 10px;
            border-radius: 5px;
            color: #fff;

            &.tag-premium{
              display: flex;
              background-color: transparent;
              border: $secondary 1px solid;
              color: $secondary;
              align-items: center;
              padding: 2px 16px 2px 2px;
              margin-left: 8px;

              .MuiSvgIcon-root{
                fill: $secondary;
                margin: 0 8px;
                width: 18px;
              }

            }

          }
        }

        .title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 5px;
          display: flex;

          .MuiSvgIcon-root{
            fill: $secondary;
            margin: 0 8px;
          }

          .tag-version{
            position: relative;
            display: inline-block;
            margin-left: 10px;
            font-size: 12px;
            font-weight: normal;
            border: #CCC 1px solid;
            padding: 5px;
            border-radius: 4px;
            color: #999;
            vertical-align: middle;
          }
        }

        .info {
          position: relative;
          display: block;
          font-size: 12px;
          color: #666;
          text-transform: uppercase;
          line-height: 16px;
        }
      }
    }
    .actions {
      position: relative;
      display: block;
    }
  }
  .contents {
    padding: 30px 0;
  }
}

.page{

  &.bg-white{
    background-color: #FFF;
  }
  &.bg-gray{
    background-color: #f2f2f2;
  }
  
}

@media only screen and (max-width: 767px) {
  .page-editor{

    .page-update{
      width: 100%;
    }
    
    .page-tools{
      flex-direction: column;

      .actions{
        width: 100%;
        margin-top: 16px;

        .btn-wrapper.side-by-side{
          display: block;
          width: 100%;

          .MuiButtonBase-root{
            display: block;
            width: 100%;
            margin-bottom: 16px;

            .MuiButton-label{
              display: flex;
            }

            .MuiButton-endIcon{

            }
          }
        }
      }

    }
  }
}