.modals__holder{
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 500;
    align-items: center;
    justify-content: center;
    transition: display ease-in-out 0.5s;

    &.opened{
        display: flex;
    }

    .modal__window{
        position: relative;
        display: block;
        width: calc(100% - 30px);
        background-color: #FFF;
        padding: 30px;
        border-radius: 5px;
        margin: 15px;
        box-sizing: border-box;

        .modal-adornment{
            position: absolute;
            display: block;
            width: 80px;
            height: 80px;
            border-radius: 40px;
            background-color: #FFBA00;
            top: -40px;
            left: 50%;
            margin-left: -40px;
            text-align: center;
            z-index: 5;
            
            &.success{
                background-color: #39B54A;
            }

            .material-icons{
                color: #fff;
                font-size: 48px;
                line-height: 80px;
            }

        }

        .modal{
            text-align: center;

            .content{
                position: relative;
                display: block;
                padding: 10px 0;
                margin-bottom: 0;
            }
        }

    }
    .modal__close-button{
        position: absolute;
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: #FFF 2px solid;
        background-color: transparent;
        color: #FFF;
        top: -40px;
        right: -40px;
        z-index: 2;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        &:hover{
            background-color: #FFF;
            color: #333;
        }

    }

    .btn-wrapper{
        margin-top: 15px;
        text-align: center;

        button{
            margin-left: 10px;
            margin-right: 10px;
            min-width: 150px;
        }

    }

}
.scroll-locked-by-modal{
    height: 100%;
    overflow: hidden;
}

@media screen and (min-width: 768px){
    .modals__holder{
        .modal__window{
            width: 500px;
            margin: 0;
        }
    }
}