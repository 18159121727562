#root{
  &.scroll-locked{
    position: fixed;
    overflow: hidden;
  }
}
.site{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .site-loading{
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #f2f2f2;
      z-index: 60;

      &.hidden{
        display: none;
      }
  }
}

.route-loading{
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.2);
}

.MuiInputBase-root {
  background-color: #FFF;
}

.page{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 120px;
}

.content{
  flex-grow: 1;
  box-sizing: border-box;
  padding-top: 120px;
}


.cookies-alert {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  justify-content: center;
  z-index: 200;
  transition: bottom ease-in-out 1s;

  &.hidden{
    bottom: - 300px;
  }

  .wrapper {
    position: relative;
    display: flex;
    width: 90%;
    background-color: rgba(33,33,33,0.95);
    color: #fff;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 6px;

    .text{
      flex-grow: 1;

      h6{
        margin: 0 0 8px 0;
        font-size: 18px;
        font-weight: bold;
      }

      p{
        margin: 0;
      }
    }

    .button{
      min-width: 200px;
      flex-grow: 0;
      padding: 0 0 0 10px;
      display: flex;

      .btn-wrapper{
        justify-content: flex-end;
        align-items: center;
      }

    }
  }
}

@media only screen and (max-width: 1024px) {
  .cookies-alert{

      .wrapper{
        .button{
          padding: 0;

          .btn-wrapper{
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
  }
  
}

@media only screen and (max-width: 767px) {
  .cookies-alert{
      width: calc(100% - 30px);
      left: 15px;

      .wrapper{
        flex-direction: column;

        .text{
          margin-bottom: 24px;
        }

        .button{
          width: 100%;
          padding: 0;

          .btn-wrapper{
            justify-content: center;
          }
        }
      }
  }
  
}

.app-loading{
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.9);

  &.hidden{
    display: none;
  }

  .MuiLinearProgress-root{
    width: 100%;
    height: 6px;
  }

}
