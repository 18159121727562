.content-selector{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.8);
    top: 0;
    left: 0;
    z-index: 50;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.hidden{
        display: none;

    }

    .window{
        position: relative;
        display: block;
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        background-color: #fff;
        box-shadow: rgba(0,0,0,0.8) 2px 2px 5px;
        transition: margin-top ease-in-out 1s;
        transition: margin ease-in-out 1s;

        .header{
            .title-bar{
                position: relative;
                display: flex;
                width: 100%;
                height: 50px;
                justify-content: space-between;
                align-items: center;
                background-color: #4caf50;
                padding: 0 10px;
                box-sizing: border-box;
    
                .title{
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
    
                .close-button{
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    cursor: pointer;
    
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }

        .body{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100% - 50px);
            padding: 20px;
            box-sizing: border-box;
            overflow: auto;

            .loading{
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                align-items: center;
                justify-content: center;
                background-color: #FFF;

                &.hidden{
                    display: none;
                }
            }

            .filters{
                position: relative;
                display: flex;
                width: 100%;
                height: auto;
                border: #CCC 1px solid;
                background-color: #f2f2f2;
                min-height: 30px;
                border-radius: 4px;
                padding: 10px;
                box-sizing: border-box;

                .ipt-box{
                    position: relative;
                    display: block;
                    margin: 0 5px;

                    &.wide-fill{
                        flex-grow: 1;
                    }

                    &.wide-sm{
                        width: 150px;
                    }
                }
            }

            .results{
                position: relative;
                display: flex;
                width: 100%;
                height: auto;
                flex-grow: 1;
                flex-direction: column;

                .results-table{
                    position: relative;
                    display: block;
                    width: 100%;
                    flex-grow: 1;
                }
                .results-pagination{
                    position: relative;
                    display: block;
                    flex-grow: 0;

                    .pagination{
                        .results{
                            display: block;
                        }
                    }
                }
            }

            .actions{
                position: relative;
                display: block;
                flex-grow: 0;
            }
        }
    }
}


@media only screen and (max-width: 767px) {

    .content-selector{
        .window{
            width: calc(100% - 32px);
            height: calc(100% - 32px);

            .body{
                display: block;

                .filters{
                    flex-direction: column;

                    .ipt-box{
                        margin: 0;
                        
                        &.wide-sm{
                            display: none;
                        }

                        &.wide-fill{
                            width: 100%;
                            margin-bottom: 16px;
                        }
                    }
                }

                .results-table{
                    td{
                        padding: 8px;
                    }
                }
                
                .actions{
                    .btn-wrapper.side-by-side.on-right{
                        display: block;
                        margin-top: 16px;

                        .MuiButtonBase-root{
                            width: 100%;
                            display: block;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }

}