@import "../../assets/styles/variables.scss";
.posts-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }
}

.full{
    .module__posts{
        .items{
            .post{
                width: calc(20% - 10px);
            }
        }
    }
}
.module__posts{
    min-height: 100px;
    margin-bottom: 40px;

    .see-all {
        position: relative;
        text-align: right;
        padding-right: 8px;
        margin-bottom: 4px;

        a{
            display: inline-flex;
            align-items: center;

            .material-icons{
                margin-left: 4px;
                text-decoration: none;
                font-size: 20px;
            }

            &:hover{
                text-decoration: none;
                font-weight: 700;
                span.material-icons{
                    text-decoration: none;
                }
            }
        }
    }

    .no-items{
        position: relative;
        display: block;
        width: 100%;
        background-color: #f2f2f2;
        padding: 24px;
        border-radius: 6px;
        box-sizing: border-box;
    }

        .post{
            position: relative;
            display: block;
            width: 25%;
            height: 300px;
            box-sizing: border-box;
            align-items: flex-start;
            justify-content: center;
            cursor: pointer;
            flex-grow: 0;
            flex-shrink: 0;
            box-sizing: border-box;
            padding: 4px;
            text-decoration: none;

            .body{
                position: relative;
                display: flex;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-color: #CCC;
                background-position: center center;
                background-repeat: no-repeat;
                border-radius: 4px;
                overflow: hidden;
                transition: all ease-in-out 0.5s;
                text-decoration: none;
                justify-content: center;

                &:before{
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    background-color: #000;
                    opacity: 0.5;
                    content: ' ';
                    transition: all ease-in-out 0.3s;
                }
    
                .label{
                    position: absolute;
                    display: block;
                    top: 16px;
                    left: 0;
                    z-index: 3;
                    
                    span{
                        display: flex;
                        background-color: $secondary;
                        border: $secondary 1px solid;
                        color: $white;
                        align-items: center;
                        padding: 2px 16px 2px 2px;
                        border-radius: 0px 4px 4px 0px;
                        
    
                        .MuiSvgIcon-root{
                            fill: $white;
                            margin: 0 8px;
                            width: 18px;
                        }
                    }
                }
    
                .info{
                    position: relative;
                    display: block;
                    padding: 20px;
                    width: 242px;
                    box-sizing: border-box;
                    z-index: 3;
                    transition: bottom ease-in 0.2s;
                    margin-top: 130px;
    
                    .date{
                        color: #fff;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
    
                    .title{
                        font-weight: bold;
                        font-size: 22px;
                        color: #fff;
                        text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
    
                &:hover{
                    background-color: #FFF;
                    box-shadow: rgba(0,0,0,0.5) 0px 0px 5px;
                    text-decoration: none;
    
                    &:before{
                        
                        opacity: 0.2;
                    }
    
                    .info{
                        bottom: 0px;
                    }
                }
            }
            
        }

}

.content-page{
    .module__posts{
        margin-bottom: 64px;
    }
}