@import "../../assets/styles/variables.scss";
.classes-page{
    .form-content-search {
        position: relative;
        display: block;
        padding: 16px;
        background-color: #f2f2f2;
        border-radius: 4px;
        margin: 16px 0px;
        border: #CCC 1px solid;
    }

    .results-loading {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        z-index: 5;

        &.hidden{
            display: none;
        }
    }

    .results-list{
    position: relative;
    display: block;

        &.hidden{
            display: none;
        }

        .badge{
            position: relative;
            display: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin: 0 auto;

            &.primary{   
                display: block;
                background-color: $primary;
            }
    
            &.attention{
                display: block;
                background-color: $secondary-light;
            }

            &.alert{
                display: block;
                background-color: $secondary-dark;
            }
        }

        .weekdays{
            display: block;
        }
    
        
    }

    .no-results {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 36px 24px;
        text-align: center;
        background-color: #f2f2f2;
        box-sizing: border-box;
        border-radius: 4px;
        border: #CCC 1px solid;
    
        &.hidden{
            display: none;
        }
    }

    .new-class-form {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.9);
        z-index: 50;
        align-items: center;
        justify-content: center;
        overflow: auto;

        &.hidden{
            display: none;
        }

        .form{
            position: relative;
            display: block;
            background-color: #FFF;
            width: 500px;
            min-height: 100px;
            border-radius: 6px;
            padding: 24px;
            box-sizing: border-box;

            .fieldset-label{
                font-weight: 600;
            }
        }
    }


}

@media only screen and (max-width: 767px) {
    .classes-page{

        .form-content-search{
            .MuiFormControl-root{
                margin-bottom: 8px;
            }
        }
        
    }
}