.banner-image-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .ipt-image{
        min-height: 275px;
        max-height: 275px;
    }
}

.module-banner-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    display: block;
    box-sizing: border-box;
    margin-bottom: 8px;
    

    &.parallax{
        background-attachment: fixed;
    }

    .banner-content{
        position: relative;
        display: flex;
        width: 100%;
        //min-height: 400px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px;
        box-sizing: border-box;

        &.mask{
            background-color: rgba(0,0,0,0.6);

            .banner-text{
                color: #f2f2f2;
            }

        }

        .banner-icon{
            max-width: 80px;
            margin-bottom: 16px;
        }

        .banner-text{
            color: #2c2c2c;
        }

        .banner-buttons{
            margin-top: 30px;
        }
    }
}