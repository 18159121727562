@import '../../assets/styles/variables.scss';

.calendar-month{

    
    .weekdays{
        position: relative;
        display: flex;
        width: 100%;

        .weekday{
            width: 14.28%;
            text-align: left;
            border-bottom: #CCC 1px solid;
            padding: 8px;
            font-weight: bold;
        }
    }

    .days{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        transition: all ease-in-out 0.7s;

        .day{
            width: 14.28%;
            height: 136px;
            padding: 12px;
            box-sizing: border-box;
            border-bottom: #CCC 1px solid;
            border-right: #CCC 1px solid;

            &:nth-child(7n){
                border-right: none;
            }

            &.weekend{
                background-color: #e2e2e2;
                &.regular{
                    &:hover{
                        background-color: #d2d2d2;
                    }
                }
            }

            &.regular{

                cursor: pointer;

                &:hover{
                    background-color: #f2f2f2;
                }
            }

            &.today{
                .day-label{
                    background-color: $primary;
                    color: #fff;
                }
            }

            .day-label{
                font-size: 14px;
                color: #666;
                border-radius: 50%;
                font-weight: 600;
                margin-bottom: 4px;
                position: relative;
                display: inline-flex;
                width: 25px;
                height: 25px;
                align-items: center;
                justify-content: center;
            }

            &.disabled{
                cursor: auto;
                
                .day-label{
                color: #D2D2D2;
                }
            }

            .event{
                position: relative;
                display: block;
                font-size: 12px;
                width: 100%;
                text-overflow: ellipsis;
                background-color: $primary-dark;
                border-radius: 4px;
                padding: 2px 4px;
                color: #FFF;
                box-sizing: border-box;
                margin: 2px;
                white-space: nowrap;
                overflow: hidden;
            }

        }
    }
}