.classes-list{
    .class {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: #CCC 1px solid;
        padding: 8px;
        box-sizing: border-box;

        &:hover{
            background-color: #f2f2f2;
        }

        .info{
            display: flex;
            align-items: center;
            flex-grow: 1;

            .date {
                background-color: #666;
                padding: 4px 8px;
                border-radius: 4px;
                color: #FFF;
                margin-right: 8px;
                font-size: 16px;
            }

            .time{
                flex-grow: 1;
            }

            .capacity{
                color: #999;
                font-size: 14px;
                margin: 0 16px;
            }


        }
    }
}
@media only screen and (max-width: 767px) {
    .classes-list{
        .class {
            flex-direction: column;

            .info{
                width: 100%;
                flex-direction: column;
    
                .date {
                    margin: 0;
                    width: 100%;
                    margin-bottom: 16px;
                }

                .time{
                    margin-bottom: 8px;
                }
    
                .capacity{
                    margin: 0;
                    margin-bottom: 24px;
                }
    
    
            }
        }
    }

}