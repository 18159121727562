@import '../../assets/styles/variables.scss';

@keyframes pulse {
  0%   {border: #CCC 1px solid;}
  25%   {border: #CCC 4px solid;}
  50%   {border: #CCC 8px solid;}
  75%   {border: #CCC 4px solid;}
  100%   {border: #CCC 1px solid;}
}

.dashboard-page {
  position: relative;
  display: block;

  .loading{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    justify-content: center;
    align-items: center;
    background-color: #FFF;

    &.hidden{
      display: none;
    }

  }

  .hidden{
    display: none;
  }

  .form-panel{
    position: relative;
    display: block;
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 6px;
    border: #CCC 1px solid;
    margin-bottom: 48px;

    &.alert-box{
      span{
        font-size: 14px;
        color: #666;
      }
    }

    .MuiInputBase-root{
      background-color: #FFF;
    }
  }

  .avatar-wrapper {
    position: relative;
    display: block;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border: #f2f2f2 8px solid;
    border-radius: 50%;
    transition: border ease-in-out 0.5s;

    &:hover{
      border: #CCC 8px solid;
    }

    .loading{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);

      &.hidden{
        display: none;
      }

    }

    

    #avatar {
      position: absolute;
      display: block;
      width: 150px;
      height: 150px;
      z-index: 2;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }

    .avatar{
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .toolbar{
    position: relative;
    display: block;
    padding: 8px 0px;
    border-bottom: #CCC 1px solid;
    border-top: #CCC 1px solid;
    margin: 16px 24px;
    box-sizing: border-box;
    text-align: center;

    .btn-wrapper{
      background-color: #f2f2f2;

      .btn{
        color: #999;
        padding: 16px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: none;

        &:hover{
          color: #FFF;
          background-color:#CCC;
        }

        &.selected{
          color: #FFF;
          background-color:$primary-dark;
        }
      }
    }
  }
}
