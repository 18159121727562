@import "../../assets/styles/variables.scss";

.mdl-modal-page{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    background-color: rgba(0,0,0,0.8);
    z-index: 70;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .modal{
        width: calc(100% - 48px);
        height: auto;
        max-width: 550px;
        max-height: calc(100% - 112px);
        background-color: #FFF;
        border-radius: 10px;
        overflow: auto;

        .title-bar{
            position: relative;
            display: flex;
            width: 100%;
            height: 50px;
            justify-content: space-between;
            align-items: center;
            background-color: $primary;
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 10px 10px 0 0;
    
            .title{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
    
            .close-button{
                background-color: transparent;
                border: none;
                color: #fff;
                cursor: pointer;
    
                &:hover{
                    color: #fff;
                }
            }
        }

        .modal-body{
            padding: 16px;
        }
    }

}