@import '../../assets/styles/variables.scss';
.comments-manager{
    padding-bottom: 100px;

    .form-content-search {
        position: relative;
        display: block;
        padding: 16px;
        background-color: #f2f2f2;
        border-radius: 4px;
        margin: 16px 0px;
        border: #CCC 1px solid;
    }

    .results{
        position: relative;
        display: block;

        .loading{
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            background-color: #FFF;
            z-index: 10;

            &.hidden{
                display: none;
            }
        }
    }

    .has-results{
        position: relative;
        display: block;
        width: 100%;

        &.hidden{
            display: none;
        }

        .comment-card{
            position: relative;
            display: flex;
            width: 100%;
            background-color: rgba(0,0,0,0.03);
            border-radius: 6px;
            margin-bottom: 24px;
            padding: 16px;
            box-sizing: border-box;
            border-left: #CCC 10px solid;
            transition: all ease-in-out 0.3s;

            &:hover{
                box-shadow: rgba(0,0,0,0.2) 0px 0px 5px;
            }

            &.new{
                border-left: $secondary-light 10px solid;
            }

            &.approved{
                border-left: $primary-dark 10px solid;
            }

            &.replied{
                border-left: $primary-light 10px solid;
            }

            &.cancelled{
                border-left: #666 10px solid;
            }

            .select{
                position: relative;
                display: flex;
                width: 30px;
                margin-right: 16px;
                align-items: center;
                justify-content: center;
            }

            .comment-body{
                position: relative;
                display: block;
                width: 100%;
                cursor: pointer;

                .owner{
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .image{
                        position: relative;
                        display: block;
                        flex-grow: 0;
                    }

                    .info{
                        flex-grow: 1;
                        padding: 0px 16px;
                        box-sizing: border-box;

                        .msg-date{
                            position: relative;
                            display: block;
                            color: #666;
                            font-size: 14px;
                            margin-bottom: 4px;
                        }
                        
                    }

                    .actions{
                        margin-top: 4px;

                        &.hidden{
                            display: none;
                        }

                        a{
                            margin-right: 16px;
                        }

                        .reject{
                            color: #F00;
                        }
                    }

                    .reply{
                        position: relative;
                        display: block;
                        background-color: rgba(0,0,0,0.05);
                        box-sizing: border-box;
                        padding: 16px;
                        border-radius: 6px;
                        margin-top: 8px;
    
                        &.hidden{
                            display: none;
                        }
    
                        .date{
                            font-size: 14px;
                            color: #666;
                        }
                    }

                }

            }
        }
    }

    .no-results{
        &.hidden{
            display: none;
        }
    }

    .legend {
        position: relative;
        display: flex;
        width: 100%;
        margin: 16px auto;
        background-color: #f2f2f2;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 16px;
        justify-content: space-around;
        align-items: center;
        color: #666;

        .item{
            margin: 0px 8px;
            div{
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 8px;
                vertical-align: middle;

                &.new{
                    background-color: $secondary-light;
                }

                &.approved{
                    background-color: $primary-dark;
                }

                &.replied{
                    background-color: $primary-light;
                }

                &.cancelled{
                    background-color: #666;
                }
            }
        }
    }

    .reply-area {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 20;
        background-color: rgba(0,0,0,0.8);
        align-items: center;
        justify-content: center;

        &.hidden{
            display: none;
        }

        .reply-form,
        .delete-form {
            position: relative;
            display: block;
            background-color: #FFF;
            border-radius: 6px;
            padding: 16px;
            box-sizing: border-box;
            width: 700px;
            max-width: calc(100% - 30px);

            &.hidden{
                display: none;
            }
        }

        .original-message {
            position: relative;
            display: flex;
            width: 100%;
            border: #CCC 1px solid;
            border-radius: 6px;
            padding: 16px;
            box-sizing: border-box;
            align-items: center;
            justify-content: flex-start;
            background-color: #f2f2f2;

            .user {
                flex-grow: 0;
                margin-right: 16px;
            }

            .message {
                flex-grow: 1;

                .date {
                    font-size: 14px;
                    color: #666;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .comments-manager{

        .form-content-search{
            .MuiFormControl-root{
                margin-bottom: 8px;
            }
        }

        .has-results{
            .comment-card{
                flex-direction: column;
                align-items: center;

                .comment-body{
                    .owner{
                        flex-direction: column;
                        align-items: center;
                        .image{
                            margin-bottom: 8px;
                        }
                    }
                }

            }
        }

        .reply-area{
            z-index: 60;

            .reply-form,
            .delete-form{
                max-width: calc(100% - 32px);
                max-height: calc(100% - 32px);
                box-sizing: border-box;
                overflow-y: auto;
            }
        }

        .legend{
            flex-direction: column;
            align-items: flex-start;

            .item{
                margin-bottom: 8px;
            }
        }
    }
}