.cta-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

    .btn-group{
        position: relative;
        display: block;
        width: 100%;
        border: #CCC 1px solid;
        border-radius: 4px;
        padding-top: 16px;
        box-sizing: border-box;

        .btn-group-label{
            font-size: 14px;
            font-weight: bold;
            color: #666;
        }
    }

}

.mdl~.mdl-cta{
    margin-top: -30px;
}
.mdl-expand-collapse~.mdl-cta{
    margin-top: -48px;
}

.content-page{
    .module-cta {
        margin-bottom: 64px;
        //margin-top: -56px;
    }
}

@media only screen and (max-width: 767px) {

    .content-page{
        .module-cta {
            margin-bottom: 36px;
        }
    }
    
}