@import "../../assets/styles/variables.scss";
.testimonials-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

    .items{
        position: relative;
        display: flex;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: #CCC 1px solid;
        border-radius: 4px;
        min-height: 100px;
        justify-content: center;

        .no-results{
            position: relative;
            display: flex;
            width: 100%;
            height: 100px;
            align-items: center;
            justify-content: center;
            color: #999;

            &.hidden{
                display: none;
            }
        }
        .card{
            position: relative;
            display: block;
            flex-grow: 1;
            padding: 16px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: rgba(0,0,0,0.2) 0px 0px 5px;
            margin: 10px;

            .avatar {
                height: 200px;
                width: 200px;
                margin: 0 auto 36px auto;

                .ipt-image{
                    border-radius: 100px;
                    .image{
                        border-radius: 100px;
                    }
                }
            }

            .text, 
            .signature, 
            .function, 
            .company, 
            .link, 
            .buttons {
                margin-bottom: 16px;
            }
        }
        
    }
}

.module-testimonials{
    position: relative;
    display: block;
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    padding: 32px;
    border-radius: 6px;

    &.gray-mode{
        background-color: rgba(0,0,0,0.05);
    }

    .items{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;

        .item{
            position: relative;
            display: block;
            padding: 16px;
            margin: 15px;
            flex-grow: 1;
            box-sizing: border-box;
            max-width: 75%;

            .avatar {
                position: relative;
                display: block;
                width: 120px;
                height: 120px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                border-radius: 60px;
                margin: 0 auto 24px auto;
            }

            .text{
                position: relative;
                display: block;
                width: 100%;
                text-align: center;
                font-size: 20px;
                font-style: italic;
                margin-bottom: 8px;
            }

            .author {
                position: relative;
                display: block;
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }

            .signature {
                position: relative;
                display: block;
                width: 100%;
                text-align: center;

                .job,
                .company {
                    position: relative;
                    display: inline-block;
                    font-size: 14px;
                    color: #999;
                }


            }

        }
    }
}

.content-page{
    .module-testimonials{
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {
    .content-page{
        .module-testimonials{
            margin-bottom: 48px;
            padding: 0;
        }
    }
}