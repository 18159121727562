@import "../../assets/styles/variables.scss";
.calendar-event{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    z-index: 15;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.loaded{
        .mask{
            opacity: 0.8;
        }
    }

    .mask{
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        background-color:#000;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all ease-in-out 0.5s;
        z-index: 17;
    }

    .form{
        position: relative;
        display: block;
        width: 550px;
        max-width: 100%;
        border-radius: 4px;
        overflow: hidden;
        z-index: 18;
    }

    .form-body{
        position: relative;
        background-color: #FFF;
        min-height: 200px;
        padding: 16px;
        box-sizing: border-box;

        .form-group{
            margin-bottom: 16px;
        }

        label{
            font-size: 16px;
            color: #666;
        }

        .aditional-info{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #999;
        }

        p.aditional-info{
            display: block;
            color: #666;
        }

        .loading{
            position: absolute;
        }
    }

    .vertical-middle{
        display: inline-flex;
        align-items: center;
    }

    .close-button{
        background-color: transparent;
        border: none;
        color: #CCC;
        cursor: pointer;

        &:hover{
            color: #666;
        }
    }

    .slot-date {
        font-size: 22px;
        font-weight: 600;
        display: block;
        margin-bottom: 4px;
    }

    .slot-time {
        font-size: 18px;
        font-weight: 400;
    }

    .free-box {
        position: relative;
        display: flex;
        width: 100%;
        background-color: darkseagreen;
        border-radius: 4px;
        padding: 16px 24px;
        box-sizing: border-box;
        margin: 16px auto;
        align-items: center;
        color: #fff;
    }

    .user-box {
        position: relative;
        display: flex;
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 4px;
        padding: 16px 24px;
        box-sizing: border-box;
        margin: 16px auto;
        align-items: center;
    }

    .avatar {
        position: relative;
        display: block;
        width: 80px;
        height: 80px;
        background-color: #CCC;
        border-radius: 50%;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 16px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .user {
        flex-grow: 1;

        .screen-name {
            font-size: 18px;
            font-weight: 600;
            display: block;
            margin-bottom: 4px;
        }

        .age {
            font-size: 16px;
            margin: 0 8px;
            font-weight: normal;
        }

        a {
            display: inline-block;
            font-size: 16px;
            margin-bottom: 2px;
        }

        .phone {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 2px;

            .material-icons {
                color: #666;
                font-size: 18px;
                margin-right: 4px;
            }
        }

        .tag {
            position: relative;
            display: inline-block;
            font-size: 12px;
            font-weight: normal;
            background-color: $primary;
            padding: 4px 8px;
            color: #FFF;
            border-radius: 4px;
        }
    }

    .title {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin: 16px auto;

        .text{
        font-size: 16px;
        font-weight: 600;
        }

        .action{
            color: $primary-darker;
            font-size: 14px;
            padding: 0 8px;
            text-decoration: none;
            cursor: pointer;

            &:hover{
                color: $primary-dark;
                text-decoration: underline;
            }
        }
    }

    .label {
        font-size: 14px;
        color: #333;
        font-weight: 600;
    }

    .notes-box {
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 16px;

        .notes-wrapper {
            position: relative;
            display: block;
            width: 100%;
            background-color: #f2f2f2;
            border-radius: 4px;
            padding: 16px;
            box-sizing: border-box;
        
            .notes{
                position: relative;
                display: block;
                width: 100%;
                padding: 0px 16px;
                box-sizing: border-box;
                max-height: 100px;
                overflow: auto;
            }

            .note {
                position: relative;
                display: block;
                margin-bottom: 16px;

                &:last-of-type{
                    margin-bottom: 0;
                }
            }

            .date {
                display: block;
                font-size: 12px;
                color: $secondary-dark;
                margin-bottom: 2px;
            }

            .text {
                font-size: 14px;
                color: #2c2c2c;
            }
        }

        
    }

    .call-box {
        position: relative;
        display: block;
        margin-bottom: 16px;

        .call{
            position: relative;
            display: flex;
            align-items: center;
            background-color: #f2f2f2;
            border-radius: 4px;
            padding: 8px 16px;
            box-sizing: border-box;
            justify-content: space-between;
            font-size: 14px;
        }

        .material-icons {
            color: $primary;
            margin-right: 8px;
        }
    }

    .btn-wrapper{
        margin-top: 16px;
    }

    .form-error-alert {
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 16px;
        border: hotpink 1px solid;
        background-color: pink;
        border-radius: 4px;
        font-size: 14px;
        color: #F00;
        margin-bottom: 16px;

        &.hidden{
            display: none;
        }
    }

    .slots-list{
        position: relative;
        display: block;
        border: #CCC 1px solid;
        border-radius: 4px;
        box-sizing: border-box;
        max-height: 200px;
        height: 200px;
        overflow: auto;

        .available-date{

            .available-date-body {
                display: none;
            }

            &.opened{
                .available-date-body {
                    display: block;
                }

            }

        }

        .available-date-header {
            position: relative;
            display: flex;
            padding: 16px;
            background-color: #f2f2f2;
            margin-bottom: 2px;
            cursor: pointer;
            align-items: center;
            justify-content: space-between;

            &:hover{
                background-color: #d6d6d6;
            }

            .date{
                font-size: 16px;
                color: #333;
                font-weight: 600;
            }
        }

       

        .available-time {
            padding: 0 16px;
            font-size: 14px;

            &:last-of-type {
                margin-bottom: 16px;
            }

            .time {
                background-color: #999;
                border-radius: 4px;
                color: #fff;
                padding: 6px 8px;
                font-size: 14px;
            }

        }
    }

    
}