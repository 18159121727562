@import '../../assets/styles/variables.scss';

.main-menu{
    position: relative;
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: flex-end;

    .menu{
        position: relative;
        display: block;
    
        .menu-item{
            position: relative;
            display: inline-flex;
            width: auto;
            margin: 0px 10px;
            height: 80px;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: $auxiliary-500;
            cursor: pointer;

            &:after {
                position: absolute;
                display: block;
                content: ' ';
                background-color: transparent;
                width: 1%;
                height: 4px;
                border-radius: 2px;
                bottom: 5px;
                transition: width ease-out 0.2s;
            }

            &:hover{
                color: $auxiliary-500;

                &:after {
                    background-color: $auxiliary-500;
                    width: 100%;
                }
            }
            &.menu-active{
                color: $secondary-dark;

                &:after {
                    background-color: $secondary-dark;
                    width: 100%;
                }

            }
        
            a{
                color: $auxiliary-400;
                text-decoration: none;
                font-weight: normal;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                min-height: 80px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }


        }
    
    }

}