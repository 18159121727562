.btn-login{
    

    .btn{
        border: 0;
        background: none;
        cursor: pointer;
    }

    &.user-connected a{
        color: #666;
    }

    &.user-disconnected a{
        color: #CCC;
    }

}