@import '../../assets/styles/variables.scss';
.user-menu{

    &.is-open{
        .menu-wrapper{
            right: 0;
        }

        .mask{
            display: block;
            opacity: 0.9;
        }
    }

    .mobile-only{
      display: none;
    }

    .mask{
        position: fixed;
        display: none;
        width: 100%;
        height: 100%;
        background-color:#000;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all ease-in-out 1s;
        z-index: 8;
    }

    .menu-wrapper{
        position: fixed;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 350px;
        background-color: #f2f2f2;
        padding: 40px 16px;
        box-sizing: border-box;
        height: 100%;
        top: 0;
        right: -400px;
        box-shadow: rgba(0,0,0,0.2) -2px 0px 5px;
        z-index: 10;
        transition: all ease-in-out 0.4s;

        .hidden{
          display: none;
        }
      
        .avatar-wrapper {
          position: relative;
          display: block;
          width: 100px;
          height: 100px;
          margin: 0 auto;
          border: #f2f2f2 8px solid;
          border-radius: 50%;
          transition: border ease-in-out 0.5s;
      
          &:hover{
            border: #CCC 8px solid;
          }
      
          .loading{
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
          z-index: 3;
          top: 0;
          left: 0;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.5);
      
            &.hidden{
              display: none;
            }
      
          }
      
          #avatar {
            position: absolute;
            display: block;
            width: 100px;
            height: 100px;
            z-index: 2;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
        }
      
          .avatar{
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #f2f2f2;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      
        .toolbar{
            position: relative;
            display: block;
            padding: 8px 0px;
            border-bottom: #CCC 1px solid;
            border-top: #CCC 1px solid;
            margin: 0px 16px;
            box-sizing: border-box;
            text-align: center;
            flex-grow: 1;
            overflow-y: auto;
      
          .btn-wrapper{
            position: relative;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            overflow: auto;
            width: auto;
            height: 100%;
            box-sizing: border-box;

            
      
            .btn{
              color: #666;
              padding: 16px;
              box-sizing: border-box;
              position: relative;
              display: flex;
              align-items: center;
              cursor: pointer;
              border: none;
              text-decoration: none;

              &.hidden{
                display: none;
              }

              .title{
                  font-size: 14px;
                  margin-left: 16px;
                  color: #666;
                  flex-grow: 1;
                  text-align: left;
              }

              .counter{
                position: relative;
                display: block;
                padding: 4px 10px;
                background-color: $secondary;
                color: #FFF;
                border-radius: 4px;

                &.hidden{
                    display: none;
                }
              }
      
              &:hover{
                color: #FFF;
                background-color:$primary-dark;

                .title{
                    color: #fff;
                }
              }
            }
          }
        } 
    }
}

@media only screen and (max-width: 767px) {

  .user-menu{

    &.is-open{
      .menu-wrapper{
          left: 0;
          right: auto;
      }
    }

    .mask{
      z-index: 15;
    }

    .menu-wrapper{
      z-index: 20;
      flex-direction: row;
      padding: 0;
      right: auto;
      left: -400px;

      .menu-options{
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 16px;
        box-shadow: rgba(0,0,0,0.2) 0px 5px 5px;
        z-index: 2;

        #avatar,
        .avatar-wrapper{
          width: 80px;
          height: 80px;
        }
        .search-box.mobile-only{
          display: block;
        }
        
        .main-menu.mobile-only{
          position: relative;
          display: flex;
          width: 100%;
          height: auto;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: center;

          .menu{
            list-style: none;
            width: 100%;
            padding: 0;
            

            .item{
              position: relative;
              display: block;
              padding: 16px;
              text-align: center;
            }
          }
        }
      }

      .admin-tools{
        flex-grow: 0;
        width: 60px;
        background-color: $primary;

        .toolbar{
          margin: 0;
          padding: 0;
          border: 0;

          .btn{
            color: #FFF;
          }

          .btn .title{
            display: none;
          }

          .btn .counter{
              position: absolute;
              display: block;
              z-index: 2;
              font-size: 12px;
              padding: 2px 4px;
              top: 4px;
              right: 12px;
          }
        }
      }
    }

  }

}