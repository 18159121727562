.category-manager{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    z-index:300;
    justify-content: center;
    align-items: center;

    &.hidden{
        display: none;
    }
    
    .window{
        position: relative;
        display: block;
        width: 600px;
        max-width: calc(100% - 32px);
        background-color: #FFF;
        transition: all ease-in-out 1s;

        .window-title{
            position: relative;
            display: flex;
            width: 100%;
            height: 50px;
            justify-content: space-between;
            align-items: center;
            background-color: #4caf50;
            padding: 0 10px;
            box-sizing: border-box;

            .title{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }

            .close-button{
                background-color: transparent;
                border: none;
                color: #fff;
                cursor: pointer;

                &:hover{
                    color: #fff;
                }
            }
        }

        .window-content{
            position: relative;
            display: block;
            width: 100%;
            min-height: 100px;
            padding: 16px;
            box-sizing: border-box;
        }

        .categories-form{
            position: relative;
        }

        .categories-list{
            position: relative;

            .toolbar{
                padding: 8px;
                box-sizing: border-box;
            }

            .list{
            position: relative;
            display: block;
            width: 100%;
            height: 300px;
            overflow: auto;
            border-top: #CCC 1px solid;

                .loading{
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 5;
                    background-color: rgba(255, 255, 255, 0.8);

                    &.hidden{
                        display: none;
                    }
                }

            }
        }

        .hidden{
            display: none;
        }

        .category,
        .subcategory{
            display: flex;
            align-items: center;
            border-bottom: #CCC 1px solid;
            background-color: #FFF;
            font-size: 14px;
            margin: 0;
            width: 100%;
            color: #666;

            &:hover{
                background-color: #f2f2f2;
            }

            .MuiFormControlLabel-root{
                width: 100%;
            }
        }

        .category.with-children{
            .MuiTypography-body1{
                font-weight: bold;
            }
        }

        .subcategory{
            .subcategory-title{
            padding-left: 32px;
            }
        }

    }
}