.content-label {
    position: relative;
    padding: 4px;
    border: #CCC 1px solid;
    border-radius: 4px;
    font-size: 12px;
    color: #999;
}
.content-title{
    position: relative;
    display: block;
    margin-top: 8px;
}
.content-author {
    font-size: 12px;
    color: #999;
}

.forms-area{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
}