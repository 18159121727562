// Edit Mode
.richtext-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .richtext-ipt{
        ol, ul{
            li{
                margin-bottom: 16px;
            }
        }
    }
}

// View Mode
.module-richtext{
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 24px;

    img{
        max-width: 100%;
    }
}

// Production
.content-page{

    .mdl-richtext:last-child .module-richtext{
        margin-bottom: 64px;
    }

    .mdl-richtext + .mdl-richtext{
        margin-top: -24px;
    }
    .module-richtext{
        margin-bottom: 48px;

        img{
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
.module-richtext{
    img{
        max-width: 100%;
    }
}
    
}

