.login-page{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .middle-justified{
        display: flex;
        align-items: center;
    }
    .top-justified{
        display: flex;
        align-items: flex-start;
    }
    
    .ipt-wrapper{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .btn-wrapper{
        position: relative;
        display: block;
        width: 100%;
        bottom: 0px;
        left: 0px;
        text-align: center;
        padding: 0;
    }

}

.terms-agreement {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 4px;

    &.has-error{
        border: #F00 1px solid;
        background-color: rgba(255, 0, 0 ,0.1);
        border-radius: 4px;

        .text{
        color: #F00;
        }
    }

    .text{
        font-size: 12px;
        color: #666;
        flex-grow: 1;
    }
}

.half-panel{
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 50px;
    width: 50%;
    height: auto;

    .panel-body{
        min-height: 200px;
    }

}
.login-panel{
    border-right: #CCC 1px solid;
}
.or-label{
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    background-color: #CCC;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    z-index: 5;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
    color: #FFF;
}

.btn-loading{
    color: #48A231;
    height: 4px;
    width: 100%;
    margin-top: -4px;
    border-radius: 2px;
}

@media only screen and (max-width: 767px) {
    .login-page{

        .top-justified{
            flex-direction: column;
            align-items: center;

            .half-panel{
                width: 100%;
                padding: 0;
                border: none;
            }

            .or-label{
                position: relative;
                z-index: 0;
                left: auto;
                top: auto;
                margin: 48px auto 24px auto;
            }

            .register-panel{
                margin-bottom: 48px;
            }
        }

    } 
}