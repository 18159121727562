@import "../../assets/styles/variables.scss";
.partner-page {
  .no-margin {
    margin: 0 !important;
  }

  .hidden{
    display: none;
  }

  .negative-margin-bottom {
    margin-bottom: -48px;
  }

  .vertical-middle {
    align-items: center;
  }

  .mt-xl {
    margin-top: 48px;
  }

  .main {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .mdl {
      max-width: 320px;
      padding: 0 24px;
    }
  }

  .mdl-banner-title {
    margin-bottom: 150px;

    .module-banner-title {
      height: 250px;
    }

    .partner-heading {
      position: absolute;
      display: flex;
      left: 0;
      top: 153px;
      z-index: 2;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .module-heading .heading-1 {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .company-name {
        position: relative;
        display: inline-block;
        background-color: $primary;
        padding: 4px 8px;
        border-radius: 4px;
        color: #fff;
        margin-bottom: 8px;
      }

      .avatar {
        position: relative;
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
        margin: 8px auto;
      }

      .name {
        position: relative;
        display: block;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .mdl-richtext {
    .since {
      font-size: 14px;
      color: #666;
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }

  .mdl-courses {
    box-sizing: border-box;
    margin-top: 16px;
    flex-grow: 1;
    border-top: #CCC 1px solid;

    .courses {
      position: relative;
      display: block;
      max-height: 295px;
      overflow: auto;
      padding: 0;
      box-sizing: border-box;
    }

    .course {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 16px 8px;
        align-items: center;
        box-sizing: border-box;
        border-bottom: #d6d6d6 1px solid;
        transition: all ease-in-out 0.2s;

      &:hover {
        background-color: #f2f2f2;
      }

      .btn-wrapper.side-by-side {
        button {
          margin: 0 10px;

          &:last-of-type {
            margin: 0;
          }
        }
      }

      .title {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
          font-weight: 600;
          font-size: 16px;
        }
      }

      .label {
        position: relative;
        display: inline-block;
        background-color: $secondary;
        color: #fff;
        border-radius: 4px;
        padding: 4px 8px;
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: 600;
        flex-shrink: 0;
      }

      .class {
        position: relative;
        display: inline-block;
        font-size: 14px;
        color: #666;
        margin-top: 4px;
      }

      .btn-wrapper {
        width: auto;
      }
    }
  }

  .mdl-contact-box {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 auto;
    background-color: #f2f2f2;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 24px;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;

    .module-richtext{
      margin-bottom: 0;
    }

    .schedule {
      position: relative;
      display: block;
      margin-bottom: 16px;
    }

    .contacts {
      position: relative;
      display: block;
      padding: 16px 0;
      flex-grow: 1;
      overflow: auto;

      .address {
        position: relative;
        margin-bottom: 16px;
        font-size: 14px;
        color: #666;

        .title {
          display: block;
          font-size: 16px;
          color: #2c2c2c;
          font-weight: 600;
          margin-bottom: 4px;
        }

        .street {
          display: block;
          margin-bottom: 2px;
        }
      }

      .phone {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;

        .material-icons {
          position: relative;
          display: inline-flex;
          width: 20px;
          height: 30px;
          color: #666;
          border-radius: 50%;
          align-items: center;
          justify-content: flex-start;
          font-size: 20px;
          margin-right: 8px;
        }
      }
    }

    .social {
      border-top: #ccc 1px solid;
      padding-top: 24px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: space-evenly;

      &.hidden{
        display: none;
      }

      a {
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: none;

        .svg-icon {
          fill: #666;
          width: 24px;
          height: 24px;
        }

        &:hover {
          .svg-icon {
            fill: $primary;
          }
        }
      }
    }
  }

  .hidden-on-tablet{
    display: none;
  }

  .mdl-posts {
    .module__posts {
      margin-bottom: 0;
    }
  }

  

}
.partner-content-list,
  .content-list{
    margin: 8px 0;
    td{
      font-size: 16px;
      padding: 4px 16px;
      color: #2c2c2c;
      cursor: initial;
    }
  }

@media only screen and (max-width: 1024px) {
  .partner-page{
    .on-sidebar{
      .mdl{
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .partner-page{
      .hidden-on-tablet{
        display: block;
      }
  
      .hidden-in-mobile{
        display: none;
      }
  
  }
}

@media only screen and (max-width: 767px) {
.partner-page{
    .hidden-on-tablet{
      display: block;
    }

    .hidden-in-mobile{
      display: none;
    }

    .mdl-courses{
        .courses{
            max-height: 100%;
        }
        .course{
            flex-direction: column;
            align-items: flex-start;
            min-height: 60px;
        }

        .btn-wrapper{
          display: none;
        }
    }

}
    

}
