@import '../../assets/styles/variables.scss';
.partners-schedule{
    .calendar-header{
        position: relative;
        display: flex;
        align-items: center;
        padding: 8px;
        margin-bottom: 16px;

        .month{
            font-size: 24px;
            font-weight: 600;
            margin-right: 8px;
        }

        .year{
            font-size: 24px;
            font-weight: normal;
        }
    }

    .loader{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #f2f2f2;
        top: 0;
        left: 0;
        z-index: 2;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        &.hidden{
            display: none;
        }
    }

    
    
}