@import "../../assets/styles/variables.scss";
.partners-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

}

.content-page .module-partners {
    margin-bottom: 64px;
}

.module-partners{
    position: relative;
    display: block;
    width: 100%;
    min-height: 100px;
    transition: height ease 0.7;

    .loading{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
    }

    .partners-filter{
        position: relative;
        display: block;
        width: 100%;

        .filters{
            background-color: #f2f2f2;
            border-radius: 4px;
            padding: 16px;
            border: #CCC 1px solid;
        }
    }

    .partners-list{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 64px;

        .MuiGrid-container{
            justify-content: flex-start;
        }

        &.missing-items{
            .MuiGrid-container{
                justify-content: center;
            }
        }

        .loading{
            z-index: 3;
        }
        
        .partner{
            position: relative;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: 8px;
            cursor: pointer;
            height: 100%;
            text-decoration: none;
            color: inherit;

            &:hover{

                .info{
                border-color: #fff;
                box-shadow: rgba(#000,0.2) 1px 1px 15px;
                text-decoration: none;
                background-color: #FFF;
                }

                .avatar{
                    box-shadow: rgba(#000,0.4) 2px 2px 15px;
                }
            }

            .banner{
                position: relative;
                display: block;
                height: 100px;
                width: 100%;
                background-color: #f2f2f2;
            }

            .avatar{
                position: relative;
                display: block;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #CCC;
                margin: 8px auto -40px auto;
                z-index: 2;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                border: #CCC 1px solid;
            }

            .info{
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                border: #CCC 1px solid;
                background-color: #FFF;
                border-radius: 6px;
                padding: 56px 16px 16px 16px;
                box-sizing: border-box;
            }

            .name{
                position: relative;
                display: block;
                font-size: 20px;
                font-weight: 700;
                text-align: center;
            }

            .company{
                position: relative;
                display: block;
                text-align: center;
                margin-bottom: 4px;

                .company-name{
                    background-color: $primary;
                    color: #FFF;
                    font-size: 12px;
                    padding: 4px 8px;
                    border-radius: 4px;
                    display: inline-block;
                }
            }

            .since{
                position: relative;
                display: block;
                font-size: 12px;
                color: #999;
                width: 100%;
                text-align: center;
                margin-bottom: 8px;
            }

            .intro{
                position: relative;
                display: block;
                width: 100%;
                padding: 8px;
                box-sizing: border-box;
                font-size: 14px;
                color: #666;
                text-align: left;
                line-height: 18px;
                flex-grow: 1;
            }

            .services{
                position: relative;
                display: block;
                width: 100%;
            }

            .service{
                position: relative;
                display: inline-block;
                padding: 4px 8px;
                background-color: $primary;
                color: #FFF;
                border-radius: 4px;
                font-size: 14px;
                margin: 4px;
            }

            .hidden{
                display: none;
            }

            .link{
                
            }
        }
    }
}