.menu-manager{

    .menu-add{
        position: relative;
        display: flex;
        background-color: #f2f2f2;
        border-radius: 4px;
        padding: 16px;
        margin: 48px auto;

        .grid-grow{
            flex-grow: 1;
            margin: 5px;

            .MuiFormControl-root{
                background-color: #FFF;
            }
            
        }
        .grid-fit{
            flex-grow: 0;
            margin: 5px;
        }
    }

    .menu-list{
        margin-bottom: 64px;

        .placeholder {
            position: relative;
            display: block;
            width: 100%;
            border: #CCC 1px solid;
            border-radius: 4px;
            padding: 8px;
            box-sizing: border-box;
            margin-bottom: 24px;

            .title{
                position: relative;
                display: block;
                background-color: #e2e2e2;
                padding: 16px;
                border-radius: 4px;
                color: #666;
                font-weight: bold;
                font-size: 16px;
            }

            .items{
                position: relative;
                display: block;
                width: 100%;

                .loading{
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 10;
                    background-color: #FFF;

                    &.hidden{
                        display: none;
                    }
                }
            }

            .item{
                position: relative;
                display: flex;
                width: 100%;
                background-color: #FFF;
                border-bottom: #e2e2e2 1px solid;

                &:hover{
                    background-color: #f2f2f2;
                }
                .label,
                .link{
                    padding: 16px;
                }
                .label{
                    width: 40%;
                }
                .link{
                    color: #666;
                    flex-grow: 1;
                }
                .options {
                    width: 150px;
                    text-align: right;
                    margin-right: 16px;
                }
            }
        }
    }

}

@media only screen and (max-width: 767px) {

    .menu-manager{

        .menu-add{
            .MuiGrid-item{
                margin-bottom: 8px;
            }
        }

        .menu-list .placeholder .item{
            flex-direction: column;
            align-items: center;

            .label,
            .link,
            .options{
                width: 100%;
                padding: 8px;
                text-align: center;
            }
        }
    }

}