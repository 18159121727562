@import '../../assets/styles/variables.scss';

.header-page{
    position: fixed;
    display: block;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: #fff;
    box-shadow:rgba(0,0,0,0.5) 1px 1px 5px;

    .mobile-menu-button{
        display: none;
    }

    .site-logo{
        position: absolute;
        bottom: -45px;
        left: 30px;
        z-index: 2;
        cursor: pointer;
    }

    .header-bar{

        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .header-bar__menu{
            position: relative;
            display: inline-block;
            width: auto;
        }
        .header-bar__blog-search{
                position: relative;
                display: inline-block;
                width: auto;
                max-width: 200px;
                box-sizing: border-box;
                overflow: hidden;
                padding: 5px;
            
                .ipt-search {
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                margin-top: 0px;
                position: relative;
                display: block;

                    .ipt{
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 30px;
                        margin: 0;
                        padding: 5px 40px 5px 10px;
                        background-color: #D6D6D6;
                        border: none;
                        border-radius: 3px;
                        box-sizing: border-box;
                    }
                    .ipt-icn{
                        position: absolute;
                        right: 5px;
                        top: 4px;
                        z-index: 2;
                        color: #FFF;
                    }

                }
        }
        .header-bar__user-panel{
            position: relative;
            display: inline-block;
            width: 60px;
            color: #d6d6d6;

            .material-icons {
                font-size: 35px;
            }

        }

    }
    
}

@media only screen and (max-width: 767px) {
    .header-page{

        .desktop-search-box{
            display: none;
        }

        &.opened{

            .mobile-menu-button{
                .sup, .mid, .inf{
                    background-color: $primary;
                }

                .sup{
                    transform: rotate(-45deg);
                    top: 4px;
                }

                .mid{
                    opacity: 0;
                }

                .inf{
                    top: 4px;
                    transform: rotate(45deg);
                }
            }
            
        }

        .site-logo{
            position: absolute;
            display: block;
            width: 75px;
            height: 85px;
            left: 50%;
            margin-left: -37.5px;
            top: 8px;
            bottom: auto;
        }

        .mobile-menu-button{
            position: absolute;
            display: block;
            width: 25px;
            height: 25px;
            left: 16px;
            z-index: 22;

            .sup, .mid, .inf{
                position: absolute;
                display: block;
                width: 25px;
                height: 4px;
                border-radius: 4px;
                background-color: #999;
                transition: all ease-in-out 0.3s;
            }

            .sup{
                top: 0;
                left: 0; 
            }

            .mid{
                top: 8px;
                left: 0;
                opacity: 1;
            }

            .inf{
                top: 16px;
                left: 0;
            }
        }

        .header-bar{
            height: 80px;

            .header-bar__menu{
                display: none;
            }
        }
    }
}


