.richtext-editor{
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    .editor{
        position: relative;
        display: block;
        width: 100%;
        padding: 10px;
        border: #CCC 1px solid;
        border-radius: 4px;
        background-color: #f9f9f9;
        margin-top: 8px;
        box-sizing: border-box;

        .title{
            font-size: 18px;
            text-align: left;
            margin-bottom: 15px;
        }
    }

    .mask{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.8);
        z-index: 2;
        top: 0;
        left: 0;
        box-sizing: border-box;
        border-radius: 4px;

        &.hidden{
            display: none;
        }

        .image-box{
            position: relative;
            display: flex;
            width: 100%;
            max-width: 400px;
            min-width: 290px;
            background-color: #f2f2f2;
            border: #CCC 1px solid;
            padding: 10px;
            border-radius: 4px;
            align-items: center;
            box-sizing: border-box;
            flex-wrap: wrap;

            &.hidden, .hidden{
                display: none;
            }

            .ipt{
                position: relative;
                display: block;
                width: 100%;
                margin-bottom: 16px;
            }

            .btns{
                position: relative;
                display: block;
                width: 100%;
            }

           
        }

        .link-box{
            position: relative;
            display: flex;
            width: 100%;
            max-width: 600px;
            min-width: 290px;
            background-color: #f2f2f2;
            border: #CCC 1px solid;
            padding: 10px;
            border-radius: 4px;
            align-items: center;
            box-sizing: border-box;

            .hidden{
                display: none;
            }

            .ipt{
                position: relative;
                display: block;
                flex-grow: 1;
            }

            .btns{
                position: relative;
                display: block;
                width: 40px;
                margin-left: 10px;
            }
            
            &.hidden{
                top: -400px;
                display: none;
            }
    
        }

    }


    
    
    .richtext-tools{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        border: #CCC 1px solid;
        background-color: #f2f2f2;
        border-radius: 3px;
        padding: 5px;
        margin: 0 0 5px 0;
        flex-wrap: wrap;

        .tool{
            position: relative;
            display: inline-block;
            margin: 0 3px;

            button{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f2f2f2;
                width: 40px;
                height: 40px;
                border: none;
                cursor: pointer;

                &:hover{
                    background-color: #FFF;
                }
            }

        }
        .separator{
            position: relative;
            display: inline-block;
            width: 3px;
            height: 20px;
            border-radius: 1px;
            background-image: linear-gradient(to right, #CCC, #FFF);
            margin: 0 5px;
        }
    }

    .richtext-ipt{
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        min-height: 30px;
        padding: 20px;
        border: #CCC 1px solid;
        background-color: #f9f9f9;
        box-sizing: border-box;

        &.error{
            border: #F00 1px solid;
        }
    }

    .richtext-error{
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        padding: 5px 0;
        color: #F00;
        font-size: 12px;
        
        &.hidden{
            display: none;
        }
    }

}


@media only screen and (max-width: 767px) {
    .richtext-editor{

        .richtext-tools{
            flex-wrap: nowrap;
            overflow-x: auto;
            height: 40px;
        }

        .richtext-ipt{
            margin-bottom: 16px;
        }

    }
}