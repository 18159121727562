@import "../../assets/styles/variables.scss";
.users-page{

    .form-content-search {
        position: relative;
        display: block;
        padding: 16px;
        background-color: #f2f2f2;
        border-radius: 4px;
        margin: 16px 0px;
        border: #CCC 1px solid;
    }

    .results-loading {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        z-index: 5;

        &.hidden{
            display: none;
        }
    }

    .results-list{
    position: relative;
    display: block;

        &.hidden{
            display: none;
        }

        .email{
            font-size: 14px;
            color: #999;
        }

        .badge{
            position: relative;
            display: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin: 0 auto;

            &.primary{   
                display: block;
                background-color: $primary;
            }
    
            &.attention{
                display: block;
                background-color: $secondary-light;
            }

            &.alert{
                display: block;
                background-color: $secondary-dark;
            }
        }
    
        
    }

    .no-results {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 36px 24px;
        text-align: center;
        background-color: #f2f2f2;
        box-sizing: border-box;
        border-radius: 4px;
        border: #CCC 1px solid;
    
        &.hidden{
            display: none;
        }
    }

    .user-edit {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.9);
        top: 0;
        left: 0;
        z-index: 30;
        justify-content: center;
        align-items: center;

        .edit-form {
            position: relative;
            display: block;
            width: 600px;
            min-height: 100px;
            background-color: #FFF;
            border-radius: 4px;
            max-width: 90%;
            max-height: 90%;
            overflow: auto;
            padding: 24px;

            .loading {
                position: absolute;
                display: flex;
                background-color: #FFF;
                z-index: 2;
                top: 0;
                left: 0;
                align-items: center;
                justify-content: center;

                &.hidden{
                    display: none;
                }
            }
        }

        &.hidden{
            display: none;
        }
       
    }
}

@media only screen and (max-width: 767px) {
    .users-page{

        .form-content-search{
            .MuiFormControl-root{
                margin-bottom: 8px;
            }
        }
        .user-edit{
            z-index: 60;

            .edit-form{
                max-width: 80vw;
                max-height: 80vh;
            }
        }
    }
}