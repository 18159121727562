@import "../../assets/styles/variables.scss";
.blog-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }
}

.module__blog{
    position: relative;
    display: block;
    width: 100%;
    padding: 36px 0px;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex-direction: column;

    .search-bar{
        position: relative;
        display: block;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 16px 0px;
        box-sizing: border-box;

        .MuiInputBase-root{
            background-color: #FFF;
        }
    }

    .blog-items{
        position: relative;
        display: flex;
        width: 100%;
        min-height: 200px;
        box-sizing: border-box;
        padding: 16px;
        margin: 0px auto;
        align-items: center;
        flex-direction: column;

        .no-results{
            position: relative;
            display: flex;
            width: 100%;
            height: 300px;
            justify-content: center;
            align-items: center;
            color: #666;
            flex-direction: column;
            background-color: #f2f2f2;
            border-radius: 6px;
            padding: 24px;

            p{
                text-align: center;

                span{
                    position: relative;
                    display: block;
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 8px;
                }
            }

            &.hidden{
                display: none;
            }
        }

        .post-card{
            position: relative;
            display: flex;
            width: 90%;
            padding: 16px;
            box-sizing: border-box;
            border-radius: 6px;
            margin: 16px 0;
            background-color: #fff;
            box-shadow: rgba(0,0,0,0.2) 0px 0px 4px;
            justify-content: flex-start;
            justify-items: flex-start;
            transition: all ease-in-out 0.3s;
            cursor: pointer;
            max-width: 900px;
            max-height: 282px;
            

            &:hover{
                box-shadow: rgba(0,0,0,0.3) 2px 2px 15px;
                transform: scale(1.05);
            }

            .image{
                position: relative;
                display: block;
                width: 300px;
                height: 220px;
                background-size: cover;
                min-width: 300px;
                background-position: center center;
                background-repeat: no-repeat;
                border-radius: 6px;
                background-color: #f2f2f2;
            }

            .label{
                position: absolute;
                display: block;
                top: 16px;
                left: 0;
                z-index: 3;
                
                span{
                    display: flex;
                    background-color: $secondary;
                    border: $secondary 1px solid;
                    color: $white;
                    align-items: center;
                    padding: 2px 16px 2px 2px;
                    border-radius: 0px 4px 4px 0px;
                    

                    .MuiSvgIcon-root{
                        fill: $white;
                        margin: 0 8px;
                        width: 18px;
                    }
                }
            }

            .info{
                position: relative;
                display: flex;
                flex-grow: 1;
                padding: 16px 36px;
                box-sizing: border-box;
                flex-direction: column;

                .category{
                    position: relative;
                    display: block;
                    margin-bottom: 8px;

                    span{
                        position: relative;
                        display: inline-block;
                        border: #CCC 1px solid;
                        padding: 4px 8px;
                        border-radius: 4px;
                        font-size: 12px;
                        color: #999;
                    }
                }

                .title{
                    font-weight: bold;
                    font-size: 22px;
                    color: #2c2c2c;
                    text-shadow: rgba(0,0,0,0.2) 0px 0px 3px;
                    margin-bottom: 16px;
                }
                .desc{
                    font-size: 16px;
                    color: #666;
                    flex-grow: 1;
                    overflow: hidden;
                }

                .next-class{
                    flex-grow: 1;

                    span{
                        background-color: #f2f2f2;
                        padding: 8px;
                        font-size: 14px;
                        border: #d2d2d2 1px solid;
                        border-radius: 4px;
                    }
                }

                .footer{
                    position: relative;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    margin-top: 16px;

                    .author{
                        position: relative;
                        display: flex;
                        width: 50%;
                        align-items: center;

                        .info{
                            position: relative;
                            display: block;
                            padding: 0 8px;
                            margin: 0;
                            flex-grow: 1;
                            color: #999;
                            font-size: 14px;

                            span.name{
                                position: relative;
                                display: block;
                                font-weight: bold;
                                font-size: 14px;
                                color: #666;
                                margin-bottom: 2px;
                            }

                            span.date,
                            span.company{
                                position: relative;
                                display: block;
                                font-size: 12px;
                            }
                        }
                    }

                    .tools{
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                        flex-grow: 1;
                        color: #CCC;

                        .tool{
                            position: relative;
                            display: flex;
                            align-items: center;
                            flex-grow: 0;
                            margin-right: 16px;

                            .MuiSvgIcon-root{
                                font-size: 16px;
                            }

                            span{
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }
        }

        .loading{
            position: relative;
            display: flex;
            width: 100%;
            height: 300px;
            top: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            z-index: 3;
            border-radius: 6px;
            padding: 24px;

            &.hidden{
                display: none;
            }

        }
    }

    .pagination-bar{
        position: relative;
        display: block;
        width: 100%;

        &.hidden{
            display: none;
        }
    }

}

.content-page{
    .module__blog {
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {

        .module__blog {
            margin-bottom: 48px;
            padding: 0;

            .blog-items{
                padding: 0;

                .post-card{
                    width: 100%;
                    padding: 8px;
                    flex-direction: column;
                    max-height: none;

                    .image{
                        max-width: 100%;
                        width: 100%;
                        height: 200px;
                        min-width: 100%;

                    }

                    

                    .info{
                        padding: 16px;

                        .footer{
                            display: none;
                        }
                        
                    }

                    
                }
            }
        }

}