@import "../../assets/styles/variables.scss";
.expand-collapse--edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

    .items{
        position: relative;
        display: block;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: #CCC 1px solid;
        border-radius: 4px;
        min-height: 100px;
        background-color: #f2f2f2;

        .no-results{
            position: relative;
            display: flex;
            width: 100%;
            height: 100px;
            align-items: center;
            justify-content: center;
            color: #999;

            &.hidden{
                display: none;
            }
        }

        .item-form{

            position: relative;
            display: block;
            width: 100%;
            border-radius: 4px;
            border: #CCC 1px solid;
            background-color: #fff;
            padding: 16px;
            margin-bottom: 16px;
            box-sizing: border-box;

            .info{
                position: relative;
                display: flex;
                width: 100%;
                margin-bottom: 16px;
 
                .tag-id{
                    position: relative; 
                    display: block;
                    width: 20%;
                    margin-right: 16px;
                }
                .title{
                    flex-grow: 1;
                }
            }

            .text{
                position: relative;
                display: block;
                width: 100%;
                margin-bottom: 16px;
            }
        }
        
    }
        
}

.module__expand-collapse{
    position: relative;
    display: block;
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    //padding:0 40px 40px 40px;
    

    .no-items{
        position: relative;
        display: block;
        width: 100%;

        &.hidden{
            display: none;
        }
    }

    .items{
        position: relative;
        display: block;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        border-top: #CCC 1px solid;

       &.hidden{
           display: none;
       }

       .expand-collapse{
           position: relative;
           display: block;
           width: 100%;

            .title{
            position: relative;
            display: flex;
            width: 100%;
            border-bottom: #CCC 1px solid;
            align-items: center;
            cursor: pointer;

                .text{
                    position: relative;
                    display: block;
                    flex-grow: 1;
                    padding: 16px;
                    font-size: 18px;
                    font-weight: normal;
                    color: #666;
                }

                .icon-open{
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-right: 16px;
                    transition: all ease-in-out 0.5s;
                }


           }

           .panel{
               position: relative;
               display: block;
               width: 100%;
               height: 1px;
               overflow: hidden;
               opacity: 0;
               transition: all ease-in-out 0.5s;

               .text{
                   position: relative;
                   display: block;
                   width: 100%;
                   padding: 42px;
                   color: #444;
                   box-sizing: border-box;
               }
           }

           &:hover{
               .title{
                    background-color: #f2f2f2;
                    
                   .text{
                       color: #2c2c2c;
                   }
               }
           }

           &.opened{

                .title{
                    background-color: #999;
                    border-bottom: transparent 1px solid;
                    color: #fff;
                    border-radius: 4px;
        
                        .text{
                            font-weight: bold;
                            color: #FFF;
                        }
        
                        .icon-open{
                            transform: rotate(45deg);
                        }
        
        
                }

                .panel{
                    height: auto;
                    overflow: visible;
                    border-bottom: #CCC 1px solid;
                    opacity: 1;
                }

           }

           

       }
       
    }
}

.content-page{
    .module__expand-collapse{
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {

  
        .module__expand-collapse{
            margin-bottom: 48px;
            padding: 0;

            .items .expand-collapse .panel .text{
                padding: 24px;
            }
        }
    

}