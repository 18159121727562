@import "../../assets/styles/variables.scss";
.autocomplete{

    .combobox{
        position: relative;
        display: block;
        width: 100%;
    }

    .combobox-ipt{
        position: relative;
        display: block;
        border: none;
        width: 100%;
        margin: 0;
        padding: 10px 14px;
        box-sizing: border-box;
        border-radius: 4px;
        border: #CCC 1px solid;
        font-size: 16px;
        background-color: #FFF;
        outline: none;

        &:hover{
            border: #333 1px solid;
        }
    }

    .combolist{
        position: absolute;
        display: block;
        width: calc(100% - 2px);
        background-color: #FFF;
        border-left: #333 1px solid;
        border-right: #333 1px solid;
        border-bottom: #333 1px solid;
        margin-top: -2px;
        border-radius: 0px 0px 4px 4px;
        z-index: 10;

        &.hidden{
            display: none;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0px 16px 16px 16px;

            li{
                position: relative;
                display: flex;
                align-items: center;
                padding: 4px 16px;
                color: #333;

                &:first-child{
                    border-top: #CCC 1px solid;
                }

                &:hover{
                    background-color: #f2f2f2;
                }

                &.hidden{
                    display: none;
                }
            }
        }

        .item-title{
            flex-grow: 1;
        }
    }

    .error-message{
        display: none;
    }

    &.error{

        .combobox-ipt{
            border: #f44336 1px solid;
        }

        .error-message{
            position: relative;
            display: block;
            color: #f44336;
            font-size: 12px;
            margin-top: 4px;
            margin-left: 14px;
            margin-right: 14px;
        }

    }
    
    &.opened{
        .combobox-ipt{
            border: #333 1px solid;
        }

        .error-message{
            display: none;
        }

    }   
   
}