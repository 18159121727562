@import "../../assets/styles/variables.scss";
.calendar-scheduler{
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 60;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);

    .close-button{
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        top: 16px;
        right: 16px;
        z-index: 5;

        .btn-close{
            position: relative;
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            border: none;
            outline: none;
            cursor: pointer;
            transition: all ease-in-out 0.3s;

            .material-icons{
                color: #CCC;
            }

            &:hover{

                .material-icons{
                    color: #666;
                }

            }
        }
    }

    &.hidden{
        display: none;
    }

    .scheduler{
        position: relative;
        display: block;
        width: calc(100% - 48px);
        max-width: 570px;
        max-height: calc(100% - 48px);
        background-color: #FFF;
        border-radius: 6px;
        padding: 24px;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
        box-sizing: border-box;

        .module-heading{
            margin-top: 0;
        }

        .days{
            position: relative;
            display: flex;
            width: 100%;
            overflow: auto;
            flex-wrap: nowrap;
            max-height: 400px;

            .loading{
                z-index: 2;
                position: absolute;
                display: flex;  
                align-items: center;
                justify-content: center;
                background-color: #FFF;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;

                &.hidden{
                    display: none;
                }
            }

            .day{
                position: relative;
                width: 100px;
                display: inline-block;
                margin: 8px;
                flex-shrink: 0;

                &:first-of-type{
                    margin-left: 0;
                }

                &:last-of-type{
                    margin-right: 0;
                }

                .header{
                    border-bottom: #CCC 1px solid;
                    margin-bottom: 4px;

                    .weekday{
                        text-align: center;
                        font-size: 12px;
                        text-transform: uppercase;
                        color: $primary-darkest;
                    }

                    .date{
                        font-size: 16px;
                        text-align: center;
                        margin-bottom: 8px;

                        .month{
                            font-weight: 600;
                            margin-left: 4px;
                        }
                    }
                }
            }

            .time{
                position: relative;
                display: block;
                border: $primary-dark 1px solid;
                background-color: rgba($primary-light, 0.5);
                color: $primary-darkest;
                padding: 16px;
                width: 100%;
                margin-bottom: 2px;
                border-radius: 4px;
                font-size: 14px;
                cursor: pointer;

                &:hover{
                    background-color: rgba($primary-light, 0.2);
                }

                &:disabled{
                    border: #CCC 1px solid;
                    background-color: #f2f2f2;
                    color: #CCC;
                    cursor: not-allowed;
                    text-decoration: line-through;
                }
            }
        }
    }
}