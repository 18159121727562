.unlock-content{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    .loading{
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;

       
    }

    .form{
      
    }

    .hidden{
        display: none;
    }
}