.my-favorites{
    margin-bottom: 120px;

    .material-icons{
        vertical-align: middle;
        color: #999;
    }

    .results{
        position: relative;
        display: block;
        width: 100%;
        min-height: 100px;
        margin-bottom: 48px;

        .loading{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 5;
            background-color: #FFF;

            &.hidden{
                display: none;
            }
        }

        .has-results{
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            margin-bottom: 64px;
            flex-wrap: wrap;

            .bookmark{
                position: relative;
                display: flex;
                width: calc(25% - 16px);
                min-width: 245px;
                height: 300px;
                background-color: #f2f2f2;
                border-radius: 6px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                align-items: flex-end;
                padding: 16px;
                box-sizing: border-box;
                transition: all ease-in-out 0.3s;
                margin: 8px;
                flex-direction: column;

                &.deleting{
                    opacity: 0.1;
                }
                
                

                &:hover{
                    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
                    z-index: 5;

                    &:after{
                        background-color: rgba(0,0,0,0.2);
                    }
                }

                &:after{
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: rgba(0,0,0,0.5);
                    content: '';
                    border-radius: 6px;
                    transition: all ease-in-out 0.3s;
                }

                .title{
                    position: relative;
                    display: flex;
                    width: 100%;
                    align-items: flex-end;
                    font-weight: bold;
                    flex-grow: 1;
                    font-size: 22px;
                    color: #fff;
                    text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
                    z-index: 2;
                    cursor: pointer;
                }

                .action{
                    position: relative;
                    display: block;
                    width: 50px;
                    height: 50px;
                    z-index: 2;

                    .MuiSvgIcon-root{
                        color: #FFF;
                    }
                }
            }

            &.hidden{
                display: none;
            }

        }

        .no-results{
            position: relative;
            display: block;
            background-color: #f2f2f2;
            border-radius: 6px;
            padding: 16px;
            box-sizing: border-box;
            text-align: center;

            &.hidden{
                display: none;
            }

        }
    }

}
@media only screen and (max-width: 767px) {

    .my-favorites{
        margin-bottom: 64px;
        
        .results{
            .has-results{
                .bookmark{
                    width: 100%;
                }
            }
        }
    }

}
