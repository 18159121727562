@import "../../assets/styles/variables.scss";
.gift-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

    .attachment-area{
        position: relative;
        display: flex;
        align-items: center;
        border: #CCC 1px solid;
        border-radius: 4px;
        padding: 4px;

        .attachment-label{
            font-size: 14px;
            color: #999;
            margin-left: 16px;
        }
    }

}

.content-page .module-gift {
    margin-bottom: 48px;
}

.mdl-heading~.mdl-gift,
.mdl-richtext~.mdl-gift{
    margin-top: -30px;
}

.module-gift{
    background-color:rgba(0,0,0,0.05);
    padding: 24px 24px 16px 24px;
    border-radius: 6px;
    
    .loading{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(242, 242, 242, 1);

        &:hidden{
            display: none;
        }
    }

    .title{
        position: relative;
        margin-bottom: 16px;
        display: block;
        width: 100%;
        padding: 8px;

        .heading-4{
            font-size: 20px;
            margin: 0;
        }

        &.hidden{
            display: none;
        }
    }
    .MuiInputBase-root {
        background-color: #FFF;
    }

    .vertical-middle{
        display: flex;
        align-items: center;
    }

}

.content-page{
    .module-form{
        margin-bottom: 36px;
    }
}

@media only screen and (max-width: 767px) {

    .module-form{
        .vertical-middle{
            flex-wrap: nowrap;
            label{
                font-size: 12px;
            }
        }
    }
    
}