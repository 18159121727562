.ipt-image{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    background-image: url("../../assets/images/add_photo_alternate-black-36dp.svg");
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: #e2e2e2;
    }
    
    .image {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 10px;
      }
  
      .clear-button{
          position: relative;
          display: block;
          width: auto;
          height: auto;
          text-align: center;
          background-color: transparent;
          border: none;
          color: #f00;
          padding: 10px;
          text-decoration: none;
          margin: 0 auto;
          z-index: 20;

          &.hidden{
              display: none;
          }
  
          &:hover{
              text-decoration: underline;
          }
      }
}