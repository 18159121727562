.footer-site{
    position: relative;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;

    .whatsapp-always-visible-button{

        position: fixed;
        display: flex;
        background-color: #48A231;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        right: 24px;
        bottom: 24px;
        z-index: 200;
        box-shadow: rgba(0,0,0,0.3) 0px 4px 6px;
        transition: all ease-out 0.3s;

        &.hidden{
            right: -50px;
        }
       

        .footer-social{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            background-size: 35px;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            font-size: 0;
            transition: all ease-in-out 0.2s;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;

            &:hover{
                background-color: rgba(255,255,255,0.3);
            }

            &.hidden{
                display: none;
            }
        }

        .footer-social.whatsapp{
            background-image: url('../../assets/social-icons/whatsapp_light_gray.svg');
        }

    }

    .footer-social-icons{

        position: relative;
        display: block;
        height: 40px;

        .footer-social{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            background-size: 35px;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            font-size: 0;
            transition: all ease-in-out 0.2s;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;

            &:hover{
                background-color: rgba(0,0,0,0.3);
            }

            &.hidden{
                display: none;
            }
        }
        .footer-social.facebook{
            background-image: url('../../assets/social-icons/facebook_light_gray.svg');
        }
        
        .footer-social.instagram{
            background-image: url('../../assets/social-icons/instagram_light_gray.svg');
        }
        
        .footer-social.youtube{
            background-image: url('../../assets/social-icons/youtube_light_gray.svg');
        }
        
        .footer-social.linkedin{
            background-image: url('../../assets/social-icons/linkedin_light_gray.svg');
        }
        
        .footer-social.whatsapp{
            background-image: url('../../assets/social-icons/whatsapp_light_gray.svg');
        }
        

    }

    .footer-diagonal{
        position: relative;
        width: 100%;
        height: 50px;
        background: linear-gradient(to bottom right, transparent 49%, #48A231 50%);

        .footer-logo-text {
            position: absolute;
            bottom: 30px;
            left: 30px;
        }
    }
    .footer-row{
        position: relative;
        display: flex;
        background-color: #48A231;
        min-height: 100px;
        box-shadow: rgba(0,0,0,0.5) 1px 5px 5px;

        .footer-abricco {
            position: absolute;
            bottom: -5px;
            left: 50%;
            margin-left: -150px;
            z-index: 4;
            pointer-events: none;
        }

        .footer-left,
        .footer-right{
            position: relative;
            display: inline-block;
            width: 50%;
        }
        .footer-left{
            text-align: left;
            padding: 30px 10px 10px 30px;
            display: flex;
            align-items: center;
        }
        .footer-right{
            text-align: right;
            color: #FFF;
            padding: 0px 30px;
        }
        
    }
    .footer-credits{
        position: relative;
        display: flex;
        width: 100%;
        height: 50px;
        background: linear-gradient(to bottom, #999 0%, #CCC 10%, #CCC 100%);;
        align-items: center;
        justify-content: space-between;

        .copyright{
            width: 50%;
            font-size: 12px;
            color: #666;
            margin-left: 30px;
        }
        .signature{
            width: 50%;
            text-align: right;

            a{
                text-decoration: none;
                color: #666;
            }
            a:hover{
                text-decoration: underline;
            }

            .signature-logo{
                vertical-align: middle;
                margin-left: 10px;
                margin-right: 30px;
            }
            .signature-text{
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {

    .footer-site{
        .footer-abricco{
            display: none;
        }

        .footer-logo-text{
            width: 120px;

            img{
                max-width: 100%;
            }
        }

        .footer-row{
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            box-shadow: none;

            .footer-left,
            .footer-right{
                display: block;
                width: 100%;
                padding: 0;
                margin: 0;
            }

            .footer-left{
                text-align: center;
                padding: 16px;
                box-sizing: border-box;
            }

            .footer-social-icons{
                height: auto;
                text-align: center;
                font-size: 0;
                padding: 0;
                margin: 0;
            }

            .footer-menu .menu .menu-item{
                display: block;
                padding: 8px;
            }
        }
        
        .footer-credits{
            padding: 8px 16px;
            height: auto;
            box-sizing: border-box;

            .copyright{
                margin: 0;
                width: auto;
                flex-grow: 1;
            }

            .signature{
                width: 60px;
    
                .signature-text {
                    display: none;
                }
    
            } 

        } 
        

    }
    
}