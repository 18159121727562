@import '../../assets/styles/variables.scss';

.box-selection{
    position: relative;
    display: block;
    width: 100%;

    label{
        position: relative;
        display: block;
        margin-bottom: 8px;
    }

    ul{
        position: relative;
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;

        .box-option{
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: #666;
            cursor: pointer;
            border: #CCC 1px solid;
            font-size: 12px;
            font-weight: bold;
            margin-right: 4px;

            &:hover{
                background-color: #f2f2f2;
            }

            &.selected{
                background-color: $primary;
                border-color: $primary;
                color: #FFF;
            }

            &:last-of-type{
                margin-right: 0;
            }
        }
    }

    .error-message{
        display: none;
        color: #f44336;
        margin: 0 14px;
        font-size: 12px;
        margin-top: 3px;
        text-align: left;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.66;
        letter-spacing: 0.03333em;
    }

    &.error{
        .error-message{
            display: block;
        }

        ul .box-option{
            border: #f44336 1px solid;
        }
    }
}