@import "../../assets/styles/variables.scss";
.carrousel{
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;

    .slides {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    }

    .slide{
        flex-shrink: 0;
    }

    .controls{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        left: 0;
        justify-content: space-between;
        align-items: center;
        pointer-events: none;

        button{
            border: none;
            outline: none;
            background: none;
            pointer-events: visible;

            span{
                font-size: 36px;
                color: rgba(255,255,255,0.4);
            }

            &:hover{
                span{
                    color: #FFF;
                }
            }
        }
    }

    .navigate{
        position: relative;
        display: flex;
        justify-content: center;
        
        .dot{
            position: relative;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin: 5px;
            background-color: #CCC;
            transition: all ease-in-out 0.3s;

            &.active{
                width: 20px;
                background-color: $primary;
            }
        }
    }
}