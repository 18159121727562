.module-wrapper {
  position: relative;
  display: block;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 4px;
  transition: all ease-in-out 0.3s;
  box-sizing: border-box;
  margin-bottom: 30px;

  &.full {
    max-width: 100%;
  }

  .actions {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .toolbar {
    position: relative;
    display: flex;
    width: 100%;
    margin: -60px auto;
    box-sizing: border-box;
    z-index: 1;
    align-items: center;
    justify-content: center;
    transition: margin ease-in-out 0.3s;

    .btn-wrapper {
      .btn-circle {
        margin: 0 5px;
      }
    }
  }

  .module {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
    z-index: 2;
    transition: all ease-in-out 0.3s;
    box-sizing: border-box;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    h4.richtext-title{
      margin: 15px auto;
    }
    
  }

  &.view-mode {
    &:hover {
      .toolbar {
        display: flex;
        margin: 0 auto;
        padding: 10px;
      }

      .module {
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px;
        background-color: #fff;
      }
    }
  }

  &.edit-mode {

    .toolbar {
      display: none;
    }

    .module {
      box-shadow: none;
      background-color: #fff;
    }
  }
}

@media only screen and (max-width: 767px) {

  .module-wrapper{
      max-width: calc(100% - 48px);

      .toolbar .btn-wrapper .btn-circle{
        margin: 2px;
      }
  }

}
