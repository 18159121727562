.module-selector {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;

  &.hidden{
    display: none;
  }

  .window {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 700px;
    height: auto;
    max-height: 500px;
    background-color: #fff;
    border-radius: 4px;

    .header {
      position: relative;
      display: flex;
      width: 100%;
      height: 50px;
      justify-content: space-between;
      align-items: center;
      background-color: #4caf50;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      flex-grow: 0;

      .title {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }

      .close-button {
        background-color: transparent;
        border: none;
        color: #fff;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }
    }

    .body {
      position: relative;
      display: block;
      width: 100%;
      flex-grow: 1;
      overflow: auto;

      .loading{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 2;
        align-items: center;
        justify-content: center;

        &.hidden{
          display: none;
        }

      }

      .modules {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        padding: 8px;
        box-sizing: border-box;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        .module {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          margin: 8px;
          border: #e6e6e6 1px solid;
          background-color: #fff;
          box-sizing: border-box;
          transition: all ease-in-out 0.3s;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background-color: #f2f2f2;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
          }

          .icon {
            position: relative;
            display: block;
            width: 100%;
            height: 90px;
          }
          .label {
            position: relative;
            display: block;
            width: 100%;
            height: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  .module-selector {
    .window{
      width: calc(100% - 32px);
      height: calc(100% -32px);
    }
  }

}
