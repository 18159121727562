// Edit Mode
.image-text-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;
    .ipt-image{
        .image{
            background-size: contain;
            min-height: 250px;
        }
    }
    
}

// View Mode
.module-imagetext{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &.compact{
        width: 70%;
        margin: 0 auto;
    }

    .avatar{
        position: relative;
        display: block;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50%;

        &.avatar-small{
            width: 150px;
            height: 150px;
        }
        &.avatar-large{
            width: 250px;
            height: 250px;
        }
    }
    .image,
    .avatar{
        max-width: 100%;
        flex: 1 0 auto;

        &.vertical{

            &.small{
                width: auto;
                height: 300px;
            }

            &.medium{
                width: auto;
                height: 500px;
            }

            &.large,
            &.module{
                width: auto;
                height: 700px;
            }

        }
        &.horizontal{

            &.small{
                width: 30%;
                height: auto;
            }

            &.medium{
                width: 50%;
                height: auto;
            }

            &.large,
            &.module{
                width: 80%;
                height: auto;
            }

        }
    }

    &.at-right{
        flex-direction: row-reverse;
    }


    .text-wrapper{
        position: relative;
        display: block;

        .richtext{
            position: relative;
            display: block;
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
        }

    }

}

// Production
.content-page{
    .module-imagetext{
        margin-bottom: 64px;
        
        .hidden{
            display: none;
        }
    }
}

@media only screen and (max-width: 767px) {

    .page-editor{
        .module-imagetext{
            width: 100%;
            flex-direction: column;
        }
    }

    .content-page{
        .module-imagetext{
            width: 100%;
            margin-bottom: 48px;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;

            .avatar{
                flex-grow: 0;
            }
            
            &.compact{
                width: 100%;
            }

            .hidden{
                display: none;
            }

            .text-wrapper{
                width: 100%;
            }
        }
    }

}