.page{
    .loading{
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #f2f2f2;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;

        &.hidden{
            display: none;
        }
    }

    &.bg-white,
    .bg-white{
      background-color: #FFF;
    }
    &.bg-gray,
    .bg-gray{
      background-color: #f2f2f2;
  
      .module-banner:after{
        background: linear-gradient(-3deg, #f2f2f2 0%, #f2f2f2 50%, transparent 51%);
      }
  
      .banner-heading:after{
        background: linear-gradient(-3deg, #f2f2f2 0%, #f2f2f2 50%, transparent 51%);
      }
  
      .footer-site{
        background-color: #f2f2f2;
      }
    }
}

.content-page{
    &.content{
    padding-top: 80px;
    }
}

.no-header{
  .content-page{
    &.content{
    padding-top: 0px;
    }
}
}
