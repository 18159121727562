.module-references{
    position: relative;
    display: block;
    width: 100%;
    

    .references{
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: space-between;

    .card{
        position: relative;
        display: flex;
        width: calc(50% - 10px);
        background-color: #f2f2f2;
        border: #CCC 1px solid;
        border-radius: 4px;
        transition: all ease-in-out 0.5s;
        cursor: pointer;

        &.next-post{
            .item{
                flex-direction: row-reverse;
                .info{
                    text-align: right;
                }
            }
        }

        &:hover{
            background-color: #fff;
        }

        .item{
            position: relative;
            display: flex;
            width: 100%;

            .thumb{
                position: relative;
                display: block;
                width: 200px;
                height: 150px;
                background-color: #CCC;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .info{
                position: relative;
                display: block;
                flex-grow: 1;
                box-sizing: border-box;
                padding: 16px;

                .category{
                    position: relative;
                    display: block;
                    width: 100%;
                    height: auto;
                    box-sizing: border-box;
                    margin-bottom: 8px;

                    span{
                        border: #CCC 1px solid;
                        padding: 2px 8px;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #999;
                        position: relative;
                        display: inline-block;
                    }
                }

                .title{
                    font-size: 18px;
                    font-weight: bold;
                    color: #2c2c2c;
                }
            }
        }

    }   
    }

}
.references-edit-mode{
    border: #CCC 1px solid;
    border-radius: 4px;
    padding: 16px;

}

@media only screen and (max-width: 767px) {
    .module-references{
        .references{
        flex-direction: column;
        .card{
            width: 100%;
            margin-bottom: 10px;

            &.next-post{
                .item{
                    flex-direction: row;
                    .info{
                        text-align: left;
                    }
                }
            }

            .item{
                .thumb{
                    width: 100px;
                    min-height: 100px;
                    height: 100%;
                }

                .info{
                    .category{
                        
                    }
                    .title{
                        font-size: 16px;
                    }
                }
            }
        }
    }
    }
}