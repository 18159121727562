.quotes-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;
}
.module-quotes{
    position: relative;
    display: block;
    text-align: center;

    .quote{
        position: relative;
        display: inline-block;
        width: auto;
        min-height: 60px;
        background-image: url('http://www.abricco.com/assets/images/quotes.png');
        background-repeat: no-repeat;
        background-position: top left;
        padding: 20px;
        box-sizing: border-box;
        
        .text{
        position: relative;
        display: block;
        padding: 10px;
        text-align: center;
        font-size: 22px;
        font-style: italic;
        }

        .author{
            color: #999;
            font-size: 14px;
            .name{
                font-weight: bold;
            }
        }
    }
}

@media only screen and (max-width: 767px) {

    .module-quotes{
        .quote{
            .text{
                font-size: 20px;
            }
        }
    }

}