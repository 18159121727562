// Edit Mode
.notes-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;
}

// View Mode
.module__notes{
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    color: #999;
    border-top: #CCC 1px solid;
    padding: 16px;
    margin-bottom: 30px;
    box-sizing: border-box;
    word-break: break-all;
}

// Production
.content-page{
    .module__notes{
        margin-bottom: 64px;
    }
}