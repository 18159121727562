@import "../../assets/styles/variables.scss";

// Edit Mode
.cards-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }
    
    .add-card-form{
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .image-area {
            position: relative;
            display: block;
            width: 200px;
            height: 150px;
            flex-grow: 0;
            box-sizing: border-box;
        }

        .fields-area{
            position: relative;
            display: block;
            flex-grow: 1;
            margin: 0 16px;
        }

        .buttons-area {
            position: relative;
            display: block;
            width: 40px;
            flex-grow: 0;
        }
    }

    .cards{
        position: relative;
        display: flex;
        margin: 16px 0px;
        flex-wrap: wrap;
        border: #CCC 1px solid;
        border-radius: 4px;

        .card{
            position: relative;
            display: block;
            width: calc(25% - 20px);
            min-height: 50px;
            background-color: #FFF;
            box-shadow: rgba(0,0,0,0.1) 0px 0px 5px;
            border-radius: 4px;
            margin: 10px;
            padding: 16px;
            box-sizing: border-box;

            &.cloned-card{
                position:absolute;
                transform:translate(-50%,-50%);
                opacity: 1 !important;
                z-index: 5000 !important;
                box-shadow: rgba(0,0,0,0.1) 0px 0px 15px;
            }

            &.add-card{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: #f2f2f2;
                cursor: pointer;
                color: #666;

                &:hover{
                    background-color: #CCC;
                    color: #fff;

                    .icon{
                        color: #fff;
                    }
                }

                .icon{
                    font-size: 48px;
                    color: #666;
                }

            }
        }

        .card-form{

            &.has-error{
                box-shadow: rgba(255,0,0,0.6) 0px 0px 5px;
            }

            .image-area{
                height: 150px;
                background-color: #f2f2f2;
                border-radius: 4px;
                margin-bottom: 40px;
            }

            .title,
            .desc,
            .link{
                margin-bottom: 16px;
            }
        }
        
    }
    
    .vertical-middle{
        display: flex;
        align-items: center;
    }

}
.page-editor{
    .module-cards{
        &.has-logo{
            margin-top: 75px;
        }
    }
}

// View Mode
.module-cards{
    position: relative;
    display: block;
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    padding: 30px 0px;

    &.has-logo{
        padding: 80px 0 30px 0;
    }

    &.gray-mode{
        background-color: #f2f2f2;
    }

    .cards-logo{
        text-align: center;
        position: absolute;
        display: block;
        width: 150px;
        height: 150px;
        top: -75px;
        left: 50%;
        margin-left: -50px;

        &.hidden{
            display: none;
        }

        .image{
            max-width: 100%;
            max-height: 100%;
        }
    }

    .cards-title{
        &.hidden{
            display: none;
        }
    }

    .cards-text{
        max-width: 1100px;
        margin: 0 auto;
        &.hidden{
            display: none;
        }
    }

    .cards-cta{
        &.hidden{
            display: none;
        }
    }

    .cards{
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 16px;
        box-sizing: border-box;
        max-width: 1170px;
        margin: 0 auto;

        &.centered{
            justify-content: center;
        }

        

        .card{
            position: relative;
            display: block;
            width: calc(25% - 20px);
            margin: 10px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: rgba(0,0,0,0.3) 0px 0px 5px;
            transition: all ease-in-out 0.5s;

            &.plain{
                background-color: transparent;
                border-radius: 6px;
                box-shadow: none;

                &:hover{
                    background-color: rgba(0,0,0,0.1);
                    box-shadow: none;
                }

                .image{
                    background-color: transparent;
                    background-size: 100px 100px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 130px;
                }
                .card-body{
                    .title,
                    .text{
                        text-align: center;
                    }
                }
            }

            &:hover{
                box-shadow: rgba(0,0,0,0.3) 0px 0px 10px; 
            }

            .card-link{
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                font-size: 0;
            }

            .image{
                position: relative;
                display: block;
                width: 100%;
                height: 150px;
                background-color: #f2f2f2;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 4px 4px 0 0;
            }

            .link{
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                z-index: 2;
                top: 0;
                left: 0;
                font-size: 0;
            }

            .card-body{
                padding: 16px;

                .title{
                    color: #2c2c2c;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 16px;
                    width: 100%;
                    word-break: break-word;
                    box-sizing: border-box;
                }
    
                .text{
                    color: #666;
                    font-size: 16px;
                    margin-bottom: 16px;
                    width: 100%;
                    word-break: break-word;
                    box-sizing: border-box;
                }

            }

            
        }

    }
    

}

.page.has-sidebar{
    .module-cards{
        .cards{
            .card{
                width: calc(50% - 20px);
            }
        }
    }
}

// Production
.content-page{
    .module-cards{
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {

    .page.has-sidebar{
        .module-cards{
            .cards{
                .card{
                    width: 100%;
                }
            }
        }
    }

    .cards-edit-mode{
        .cards{
            .card{
                width: 100%;
            }
        }    
    }

    .module-cards{
        .cards{
            .card{
                width: 100%;
            }
        }
    }

    .content-page{
        .module-cards{
            margin-bottom: 48px;

            .cards{
                .card{
                    width: 100%;
                }
            }
        }
    }

}