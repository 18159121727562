.page-preview{
    .preview-advice{
        position: absolute;
        margin: 80px auto 0 auto;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        box-sizing: border-box;
        z-index: 5;
        display: flex;
        padding: 15px;
        align-items: center;
        color: #FFF;
        justify-content: flex-end;

        .back-button{
            margin-right: 15px;
            color: #FFF;
            border-color: #FFF;
            background-color: transparent;

            &:hover{
                background-color: rgba(0,0,0, 0.5);
            }

            &:active{
                background-color: #fff;
                color: #2c2c2c;
            }
        }
    }
}