@import "../../assets/styles/variables.scss";

// Edit Mode
.banner-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;
    
    .add-banner-form{
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .image-area {
            position: relative;
            display: block;
            width: 200px;
            height: 150px;
            flex-grow: 0;
            box-sizing: border-box;
        }

        .fields-area{
            position: relative;
            display: block;
            flex-grow: 1;
            margin: 0 16px;
        }

        .buttons-area {
            position: relative;
            display: block;
            width: 40px;
            flex-grow: 0;
        }
    }

    .banners-list{
        margin: 16px 0 0 0;
        border-bottom: #CCC 1px solid;

        .banner{
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            border-top: #CCC 1px solid;
            padding: 10px;
            box-sizing: border-box;
            align-items: center;
            background-color: #fff;

            &:hover{
                background-color: #f2f2f2;
            }

            .image{
                position: relative;
                display: block;
                width: 150px;
                height: 100px;
                background-size: cover;
                border-radius: 4px;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 10px;
            }
            .info{
                flex-grow: 1;

                .label{
                    position: relative;
                    display: inline-block;
                    color: #999;
                    border: #CCC 1px solid;
                    border-radius: 4px;
                    padding: 4px 10px;
                    font-size: 12px;
                    margin-bottom: 8px;
                }
                .text{
                    font-weight: bold;
                    font-size: 18px;
                }
                .link{
                    font-size: 14px;
                }
            }

            .buttons{
                width: 50px;
            }
        }
    }
    .vertical-middle{
        display: flex;
        align-items: center;
    }

}

// View Mode
.module-banner{
    position: relative;
    display: block;

    /*
    &:after{
        content: ' ';
        position: absolute;
        display: block;
        width: 100%;
        height: 100px;
        bottom: 0;
        left: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(-3deg, #FFF 0%, #FFF 50%, transparent 51%);
        z-index: 5;
    }
    */

    .btn-next,
    .btn-previous{
        position: absolute;
        display: block;
        width: 35px;
        height: 30px;
        top: 50%;
        margin-top: -15px;
        color: #fff;
        z-index: 10;
        cursor: pointer;
    }
    .btn-next{
        right: 10px;
    }
    .btn-previous{
        left: 10px;
    }

    .navigator{
        position: absolute;
        display: block;
        width: 100%;
        height: 50px;
        left: 0;
        bottom: 30px;
        z-index: 10;
        text-align: center;

        .dot{
            position: relative;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            border: #CCC 2px solid;
            background-color: transparent;
            margin: 5px;
            box-sizing: border-box;
            cursor: pointer;

            &.active{
                background-color: #CCC;
            }
        }
    }
    
    .banners{
        position: relative;
        display: block;
        width: 100%;
        height: 550px;
        overflow: hidden;
        background-color: #f2f2f2;
        clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%);
        
        .banner{
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all ease-in-out 1s;
            align-items: center;
            padding: 30px 80px;
            box-sizing: border-box;
            cursor: pointer;

            &:before{
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                content: ' ';
                background-color: rgba(0,0,0,0.5);
                top: 0;
                left: 0;
                z-index: 0;

            }

            &.active{
                opacity: 1;
                z-index: 2;
            }

            .label{
                position: relative;
                display: inline-block;
                color: #FFF;
                border: #FFF 1px solid;
                padding: 4px 16px;
                border-radius: 4px;
                margin-bottom: 8px;
            }

            .text{
                position: relative;
                display: block;
                width: 100%;
                color: #FFF;
                font-weight: bold;
                font-size: 42px;

                &:after{
                    position: relative;
                    display: block;
                    width: 30%;
                    height: 6px;
                    border-radius: 3px;
                    background-color: $secondary;
                    margin-top: 8px;
                    content: ' ';
                    
                }
            }
        }
    }
}

// Production
.content-page{
    .module-banner{
        margin-bottom: 48px;
    }
}

@media only screen and (max-width: 767px) {

    .module-banner{
        .navigator{
            bottom: 0;
        }
        
        .banners{
            height: 75vh;
            clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%);
            .banner{
                padding: 30px 50px;
                .banner-info{
                    text-align: center;
    
                    .text{
                        font-size: 32px;

                        &:after{
                            margin: 8px auto;
                        }
                    }
                }
            }
            
        }
    }

    .page-editor{
        .module-banner{
            .banner{
                pointer-events: none;
            }
        }
    }

    .banner-edit-mode{
        .add-banner-form{
            flex-direction: column;

            .image-area{
                margin-bottom: 32px;
            }

            .buttons-area{
                margin-top: 16px;
            }

        }

        .banners-list{
            margin-bottom: 16px;
        }

        
    }

}