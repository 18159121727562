@import "../../assets/styles/variables.scss";

.calendar-config{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    background-color: rgba(0,0,0,0.8);
    z-index: 15;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .form{
        position: relative;
        display: block;
        width: 550px;
        max-width: 100%;
        border-radius: 4px;
        overflow: hidden;
    }

    .form-body{
        background-color: #FFF;
        min-height: 200px;
        padding: 16px;
        box-sizing: border-box;

        .form-group{
            margin-bottom: 16px;
        }

        label{
            font-size: 16px;
            color: #666;
        }

        .aditional-info{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #999;
        }
    }

    .title-bar{
        position: relative;
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        background-color: $primary;
        padding: 0 10px;
        box-sizing: border-box;

        .title{
            font-size: 20px;
            font-weight: bold;
            color: #fff;
        }

        .close-button{
            background-color: transparent;
            border: none;
            color: #fff;
            cursor: pointer;

            &:hover{
                color: #fff;
            }
        }
    }

    .alert-box {
        position: relative;
        display: flex;
        border-radius: 4px;
        padding: 16px;
        box-sizing: border-box;
        margin-bottom: 16px;
        font-size: 14px;

        .message{
            flex-grow: 1;
            color: #333;
        }

        .close-button {
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: 0;
            color: rgba(0,0,0,0.5);
            cursor: pointer;

            .MuiSvgIcon-root{
                font-size: 18px;
            }
        }

        &.hidden{
            display: none;
        }

        &.info{
            background-color: lemonchiffon;
        }

        &.error{
            background-color: pink;
        }

        &.success{
            background-color: lightgreen;
        }
    }

}