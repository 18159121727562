.author-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

}
.module-author{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

    .avatar{
        position: relative;
        display: block;
        background-color: #f2f2f2;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 60px;
        height: 60px;
        min-width: 60px;
        border-radius: 30px;
        margin-right: 16px;
        border: #CCC 1px solid;
    }

    .info{
        .author-info{
            font-weight: bold;
        }
        .update-info{
            font-size: 12px;
            color: #666;
        }
    }

}

.content-page{
    .module-author{
        margin-bottom: 48px;
    }
}