.module-spacing{
    min-height: 16px;

    &.space-1{
        min-height: 16px;
    }

    &.space-2{
        min-height: 24px;
    }

    &.space-3{
        min-height: 36px;
    }

    &.space-4{
        min-height: 48px;
    }
}

.spacing-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;
  }