@import "../../assets/styles/variables.scss";
// View Mode
.module-heading {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 15px auto;
    text-align: center;
    color: $auxiliary-700;

    &.dark-mode{
        color: #fff;
    }
  
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }
  
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 18px;
      text-align: left;
    }
    
    h1, h2, h3{
      &:after {
        content: " ";
        width: 10%;
        height: 4px;
        border-radius: 3px;
        background-color: #ffb934;
        position: relative;
        display: block;
        margin: 10px auto;
      }

      &.left{
        &:after{
          margin: 10px 0;
        }
      }
    }
  }
  
  // Edit Mode
  .heading-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;
  }

  // Left Headings
  h3.left{
    &:after{
      display: none;
    }
  }

  // Production
  .content-page{

    > .mdl-heading {
      margin-top: 48px;
    }
    
    .module-heading{
      margin: 0 auto;

      .heading-1{
        margin-bottom: 36px;
      }
      .heading-2,
      .heading-3{
        margin-bottom: 24px;
      }

      .heading-4,
      .heading-5,
      .heading-6{
        margin-bottom: 24px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .module-heading{
        h1 {
          font-size: 32px;
        }
        h2 {
          font-size: 26px;
        }
        h3 {
          font-size: 20px;
        }
        h4 {
          font-size: 18px;
          text-align: left;
        }
    }

    // Production
  .content-page{

    > .mdl-heading {
      margin-top: 36px;
    }
    
    .module-heading{
      margin: 0 auto;

      .heading-1{
        margin-bottom: 32px;
      }
      .heading-2,
      .heading-3{
        margin-bottom: 24px;
      }

      .heading-4,
      .heading-5,
      .heading-6{
        margin-bottom: 20px;
      }
    }
  }
  }