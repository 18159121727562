@import "../../assets/styles/variables.scss";

.media-gallery{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.7);
    z-index:300;
    justify-content: center;
    align-items: center;

    &.hidden{
        display: none;
    }

    .mobile-only{
        display: flex;
    }
    .desktop-only{
        display: none;
    }

    .holder{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: rgba(0,0,0,.8) 5px 5px 5px;
        overflow: hidden;

        /* TITLE BAR ****************************************************************************** */
        .title-bar{
            position: relative;
            display: flex;
            width: 100%;
            height: 50px;
            justify-content: space-between;
            align-items: center;
            background-color: #4caf50;
            padding: 0 10px;
            box-sizing: border-box;

            .title{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }

            .close-button{
                background-color: transparent;
                border: none;
                color: #fff;
                cursor: pointer;

                &:hover{
                    color: #fff;
                }
            }
        }

        /* CONTENTS ******************************************************************************* */
        .body{
            position: relative;
            display: block;
            width: 100%;
            height: calc(100% - 50px);
            background-color: #f2f2f2;
            box-sizing: border-box;
            
            &.hidden{
                display: none;
            }

                /* UPLOAD AREA */
                .upload-area{
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    box-sizing: border-box;
                    padding: 20px;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    transition: all ease-in-out 0.5s;

                    &.hidden{
                        left: -100%;
                    }

                    .ipt-box{
                        width: 100%;
                        margin: 0 0 10px 0;    

                        .MuiFormHelperText-root{
                            margin-left: 0;
                        }
                    }

                    .upload-drop{
                        position: relative;
                        display: block;
                        width: 100%;
                        height: auto;
                        margin-bottom: 15px;
    
                        .upload-field{
                            position: relative;
                            display: flex;
                            width: 100%;
                            height: 250px;
                            align-items: center;
                            justify-content: center;
                            border: #CCC 2px dashed;
                            background-color: #e6e6e6;
                            border-radius: 5px;
                            text-align: center;
                            color: #999;
    
                            &:focus{
                                outline-color: #4caf50;
                            }
    
                            .ipt-file{
                                position: absolute;
                                display: block;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                z-index: 10;
                            }
    
                            .img-preview{
                                position: absolute;
                                display: block;
                                width: 100%;
                                height: 100%;
                                opacity: 1;
                                top: 0;
                                left: 0;
                                z-index: 5;
                                background-size: contain;
                                background-position: center center;
                                background-repeat: no-repeat;
            
                                &.filled{
                                    background-color: #f2f2f2;
                                }
                            }

                            .info{
                                position: relative;
                                display: block;

                                &.hidden{
                                    display: none;
                                }
                            }
            
                            .MuiSvgIcon-root{
                                font-size: 48px;
                            }
    
                        }
    
                        &.error{
                            .upload-field{
                                border-color: #f44336;
                            }
                            .MuiFormHelperText-root{
                                color: #f44336;
                            }
                        }
        
                        
                    }

                }
                
                /* COLLECTIONS AREA */
                .collections-area{
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    box-sizing: border-box;
                    padding: 20px;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    transition: all ease-in-out 0.5s;

                    &.hidden{
                        left: -100%;
                    }

                    .MuiFormHelperText-contained{
                        margin: 0;
                    }

                    .ipt-box{
                        width: 100%;
                        margin: 0 0 15px 0;
                    }
    
                    .btn-wrapper{
    
                        .MuiCircularProgress-root{
                            position: absolute;
                            display: block;
                            top: 50%;
                            left: 50%;
                            z-index: 2;
                            margin-top: -18px;
                            margin-left: -18px;
                        }
    
                    }

                    .ipt-add-collection{
                        
                        .MuiSvgIcon-root{
                            color: $primary-dark;
                            cursor: pointer;
    
                            &:hover{
                                color: $primary;
                            }
                        }

                    }

                    /* COLLECTIONS LIST */
                    .collections{
                        position: relative;
                        width: 100%;
                        height: calc(100% - 220px);
                        overflow-x: hidden;
                        overflow-y: auto;
                        margin-top: 10px;
    
                        .collection{
                            position: relative;
                            display: flex;
                            width: 100%;
                            height: 50px;
                            padding: 0px 15px;
                            box-sizing: border-box;
                            border-bottom: #CCC 1px solid;
                            align-items: center;
                            color: #999;
                            justify-content: space-between;
        
                            &.active{
                                background-color: #e2e2e2;
                                color: #333;
                                font-weight: bold;
                            }
        
                            span,
                            .MuiSvgIcon-root{
                                cursor: pointer;
                            }
        
                            span:hover,
                            .MuiSvgIcon-root:hover{
                                color: #666;
                            }
                        }
                    }
                
                }

                /* EDITING AREA */
                .editing-area{
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    box-sizing: border-box;
                    padding: 20px;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    transition: all ease-in-out 0.5s;

                    &.hidden{
                        left: -100%;
                    }

                    .image-sample,
                    .image-info{
                        display: none;
                    }

                    .ipt-box{
                        width: 100%;
                        margin: 0 0 10px 0;    

                        .MuiFormHelperText-root{
                            margin-left: 0;
                        }
                    }
                }

                /* MAIN AREA */
                .browse{
                    position: relative;
                    display: block;
                    height: 100%;
                    margin-left: 0px;
                    flex-grow: 1;

                    /* GALLERY HEADER */
                    .gallery-header{
                        position: relative;
                        display: flex;
                        margin: 10px;
                        box-sizing: border-box;
                        justify-content: center;
                        align-items: center;
                        height: 120px;
                        border-bottom: #CCC 1px solid;
                        flex-direction: column-reverse;

                        .search-area {
                            margin-bottom: 10px;
                        }

                        .collection-info{
                            text-align: center;
                            margin-bottom: 10px;
                            
                            .title{
                                margin: 0;
                            }
                            .results{
                                color: #666;
                            }
                        }

                    }

                    /* GALLERY BODY */
                    .gallery-body{
                        position: relative;
                        display: block;
                        margin: 15px;
                        height: calc(100% - 245px);
                        box-sizing: border-box;

                        .results-loading,
                        .results,
                        .no-results{
                            &.hidden{
                                display: none;
                            }
                        }

                        /* LOADING RESULTS */
                        .results-loading{
                            position: absolute;
                            display: flex;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            width: 100%;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            background-color: #f2f2f2;
                        }

                        /* NO RESULTS */
                        .no-results{
                            position: relative;
                            display: flex;
                            width: 100%;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            color: #444;

                            .MuiSvgIcon-root{
                                display: block;
                                width: 36px;
                                height: 36px;
                                margin-right: 10px;

                            }

                        }

                        /* LISTING RESULTS */
                        .results{
                            position: relative;
                            display: block;
                            width: 100%;
                            height: 100%;
                            box-sizing: border-box;
                            overflow: auto;


                            .list{
                                position: relative;
                                display: flex;
                                width: 100%;
                                height: auto;
                                box-sizing: border-box;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                            

                                .item{
                                    position: relative;
                                    display: inline-block;
                                    width: calc(50% - 20px);
                                    max-width: 200px;
                                    height: 100px;
                                    background-color: #fff;
                                    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px;
                                    margin: 10px;

                                    &.active{
                                        background-size: contain;

                                        .selected-icon{
                                            background-color: rgba(0,0,0,0.7);

                                            &:hover{
                                                background-color: rgba(0,0,0,0.6);
                                            }

                                            .MuiSvgIcon-root{
                                                display: block;
                                            }

                                        }
                                    }

                                    .selected-icon{
                                        position: absolute;
                                        display: flex;
                                        top: 0;
                                        right: 0;
                                        width: 100%;
                                        height: 100%;
                                        justify-content: center;
                                        align-items: center;
                                        background-color: rgba(0,0,0,0.2);
                                        box-sizing: border-box;
                                        z-index: 2;
                                        transition: all ease-in-out 0.3s;
                                        cursor: pointer;

                                        &:hover{
                                            background-color: rgba(0,0,0,0.3);
                                        }
                                        
                                        .MuiSvgIcon-root{
                                            display: none;
                                            font-size: 48px;
                                            color: #fff;
                                        }
                                    }

                                    .tools{
                                        position: absolute;
                                        display: flex;
                                        width: 40px;
                                        height: 40px;
                                        padding: 5px;
                                        top: 0;
                                        right: 0;
                                        box-sizing: border-box;
                                        justify-content: flex-end;
                                        align-items: flex-start;
                                        z-index: 3;
                                    }

                                    .photo{
                                        position: relative;
                                        display: flex;
                                        width: 100%;
                                        height: 100%;
                                        z-index: 0;
                                        justify-content: center;
                                        align-items: center;
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                    }
                                }
                            }
                            .see-more{
                                position: relative;
                                display: block;
                                padding: 10px;

                                &.hidden{
                                    display: none;
                                }
                            }
                        }
                    }

                    /* GALLERY FOOTER */
                    .gallery-footer{
                        margin: 15px;
                        height: 70px;
                        box-sizing: border-box;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-top: none;

                        
                    }

                }

        }

    }
}

@media screen and (min-width: 768px){
    .media-gallery{

        .mobile-only{
            display: none;
        }
        .desktop-only{
            display: flex;
        }

        .holder{
        display: flex;
        flex-direction: column;
        width: calc(100% - 30px);
        max-width: 980px;
        height: calc(100% - 60px);

            .body{
                .upload-area{
                    width: 290px;
                    &.hidden{
                        left: -290px;
                    }
                }
                .collections-area{
                    width: 290px;
                    left: 0;
                    &.hidden{
                        left: -290px;
                    }
                }
                .editing-area{
                    width: 290px;
                    &.hidden{
                        left: -290px;
                    }

                    .image-sample{
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 200px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin: 0;
                        border-radius: 5px;
                        background-position: center center;
                    }

                    .image-info{
                        position: relative;
                        display: block;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 5px;
                        text-align: center;
                        margin: 0 0 30px 0;

                        .MuiTypography-root{
                            color: #999;
                        }
                    }

                }
                .browse{
                    margin-left: 290px;

                    .gallery-header{
                        position: relative;
                        display: flex;
                        margin: 15px;
                        box-sizing: border-box;
                        justify-content: space-between;
                        align-items: center;
                        height: 70px;
                        border-bottom: #CCC 1px solid;
                        flex-direction: row;

                        .search-area {
                            margin-bottom: 0px;
                        }

                        .collection-info{
                            text-align: left;
                            margin-bottom: 0px;
                        }
                    }

                    .gallery-body{
                        height: calc(100% - 190px);

                        .results{
                            .list{
                                .item{
                                    height: 150px;
                                }
                            }
                        }
                    }

                    .gallery-footer{
                        margin: 15px;
                        height: 60px;
                        justify-content: flex-end;
                        align-items: flex-end;
                        border-top: #CCC 1px solid;
                    }
                }
            }

        }

    }
}