/* Sass Variables */

/* Colors */
$white: #fff;
$black: #000;
$primary: #51BE35;
$primary-light: #61D346;
$primary-dark: #48A231;
$primary-darker: #368023;
$primary-darkest: #186706;
$secondary: #F7931E;
$secondary-light: #FFB934;
$secondary-dark: #F15A24;
$auxiliary-100: #f5f5f5;
$auxiliary-200: #e6e6e6;
$auxiliary-300: #CCC;
$auxiliary-400: #999;
$auxiliary-500: #666;
$auxiliary-600: #333;
$auxiliary-700: #2c2c2c;