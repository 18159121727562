@import "../../assets/styles/variables.scss";
.img-avatar{
    position: relative;
    display: block;
    background-color: #f2f2f2;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: #CCC 1px solid;
    margin: 0 auto;

    &.micro{
        width: 35px;
        height: 35px;
    }

    &.small{
        width: 40px;
        height: 40px;
    }

    &.medium{
        width: 100px;
        height: 100px;
    }

    &.large{
        width: 150px;
        height: 150px;
    }

    &.huge{
        width: 250px;
        height: 250px;
    }

    

}