.leadtext-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;
}

.module-leadtext{
    position: relative;
    display: block;
    font-size: 22px;
    font-style: italic;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
}

.content-page{
    .module-leadtext{
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {
   
    .content-page{
        .module-leadtext{
            font-size: 20px;
            margin-bottom: 48px;
        }
    }

}