.contact-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }

}

.module-contact{
    
    .loading{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(242, 242, 242, 1);
        border-radius: 6px;

        &:hidden{
            display: none;
        }
    }

    .module-body{
        position: relative;
        display: flex;
        width: 100%;

        .form-area{
            position: relative;
            display: block;
            flex-grow: 1;
            flex-shrink: 1;
            
            .form{
                position: relative;
                display: block;
                background-color: rgba(0,0,0,0.05);
                padding: 24px 24px 16px 24px;
                border-radius: 6px;
                max-width: 600px;
                margin: 0 auto;

                .title{
                    position: relative;
                    margin-bottom: 16px;
                    display: block;
                    width: 100%;
                    padding: 8px;
            
                    &.hidden{
                        display: none;
                    }
                }
                .MuiInputBase-root {
                    background-color: #FFF;
                }
            
                .vertical-middle{
                    display: flex;
                    align-items: center;
                }

                .terms{
                    position: relative;
                    display: flex;
                    width: 100%;

                    .MuiButtonBase-root,
                    label {
                        position: relative;
                        display: block;
                    }
                }
            }
        }

        .text-area{
            position: relative;
            display: block;
            width: 50%;
            min-width: 50%;

            &.hidden{
                display: none;
            }

            .richtext{
                padding: 30px;
                box-sizing: border-box;
                width: 100%;
            }
        }
    }

    

    

}

.content-page{
    .module-contact{
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {

    .content-page{
        .module-contact{
            margin-bottom: 48px;

            .module-body{
                flex-direction: column;

                .form-area,
                .text-area{
                    width: 100%;
                }

                .form-area{
                    .title{
                        padding: 0;
                        margin: 0;
                    }
                    .terms{
                        font-size: 12px;
                    }
                }
            }
        }
    }

}