@import "../../assets/styles/variables.scss";
.directory-edit-mode{
    position: relative;
    display: block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    border: #CCC 1px solid;
    padding: 15px;
    border-radius: 4px;

    .vertical-middle{
        display: flex;
        align-items: center;
    }

    .module-title-bar{
        position: relative;
        display: block;
        background-color: #666;
        padding: 8px 16px;
        color: #FFF;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
    }
}

.module__directory{
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex-direction: column;

    .search-bar{
        position: relative;
        display: block;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 16px 0px;
        box-sizing: border-box;

        .MuiInputBase-root{
            background-color: #FFF;
        }
    }

    .directory-items{
        position: relative;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        margin: 0px auto;
        align-items: center;
        flex-direction: column;

        
        .results {
            position: relative;
            display: block;
            width: 100%;
            border-top: #CCC 1px solid;

            .item{
                
            font-size: 18px;
            padding-left: 16px;
                
            }

            .author{
                border: #CCC 1px solid;
                padding: 4px 8px;
                border-radius: 4px;
                color: #999;
            }
           
        }
       

        .no-results{
            position: relative;
            display: flex;
            width: 100%;
            height: 300px;
            justify-content: center;
            align-items: center;
            color: #666;
            flex-direction: column;
            background-color: #f2f2f2;
            border-radius: 6px;
            padding: 24px;

            p{
                text-align: center;

                span{
                    position: relative;
                    display: block;
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 8px;
                }
            }

            &.hidden{
                display: none;
            }
        }


        .loading{
            position: relative;
            display: flex;
            width: 100%;
            height: 300px;
            top: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            z-index: 3;
            border-radius: 6px;
            padding: 24px;

            &.hidden{
                display: none;
            }

        }
    }

    .pagination-bar{
        position: relative;
        display: block;
        width: 100%;

        &.hidden{
            display: none;
        }
    }

}

.content-page{
    .module__directory {
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 767px) {

        .module__directory {
            margin-bottom: 48px;
            padding: 0;

            .directory-items{
                padding: 0;

            }
        }

}