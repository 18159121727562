@import '../../assets/styles/variables.scss';
.calendar-week{
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;

    .loader{
        position: relative;
        display: flex;
        width: 100%;
        height: 500px;
        background-color: #f2f2f2;
        align-items: center;
        justify-content: center;
    }

    .previous-area, 
    .next-area {
        position: relative;
        display: block;
        flex-grow: 0;
        width: 1px;
        background-color: #f2f2f2;
    }

    .previous-area{
        .btn-circle{
            position: absolute;
            z-index: 4;
            top: 50%;
            margin-top: -20px;
            left: -20px;
            box-shadow: #999 0px 2px 5px;
        }
    }

    .next-area{
        .btn-circle{
            position: absolute;
            z-index: 4;
            top: 50%;
            margin-top: -20px;
            right: -20px;
            box-shadow: #999 0px 2px 5px;
        }
    }

    .week {
        position: relative;
        display: block;
        flex-grow: 1;
        border-bottom: #CCC 1px solid;
    }

    .week-header {
        position: relative;
        display: flex;
        width: 100%;
        background-color: #CCC;
    }

    .weekday-header-time{
        position: relative;
        display: block;
        width: 70px;
        background-color: #CCC;
        padding: 8px;
        box-sizing: border-box;
    }
    .weekday-header-days {
        position: relative;
        display: flex;
        width: calc(100% - 70px);
    }
    .weekday-header {
        position: relative;
        display: block;
        width: 14.28%;
        flex-shrink: 0;
        background-color: #CCC;
        padding: 8px;
        box-sizing: border-box;

        .label{
            font-size: 14px;
            font-weight: 600;
            margin-right: 8px;
        }
        .day{
            font-size: 14px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        &.today{
            .day {
                background-color: $primary;
                color: #FFF;
            }
        } 
    }

    .week-body{
        position: relative;
        display: flex;
        width: 100%;
        overflow-y: auto;
        height: 500px;
        scroll-behavior: smooth;

        .time {
            position: relative;
            display: block;
            width: 100%;
            border-bottom: #f2f2f2 1px solid;
            border-right: #f2f2f2 1px solid;
            font-size: 12px;
            padding: 4px;
            color: #666;
            height: 60px;
            box-sizing: border-box;
            text-align: center;
        }

        .line {
            position: relative;
            display: block;
            width: 100%;
            height: 60px;
            border-bottom: #f2f2f2 1px solid;
            border-right: #f2f2f2 1px solid;
            box-sizing: border-box;

            .subline {
                position: relative;
                display: block;
                width: 100%;
                height: 30px;
                border-bottom: #f2f2f2 1px dotted;
            }
        }


        .weekday{
            position: relative;
            display: block;
            width: 14.28%;

            &.weekend{
                .line{
                background-color: #f9f9f9;
                }
            }
            
            &:last-of-type{
                .line{
                    border-right: none;
                }
            }
        }

        .events {
            position: absolute;
            display: block;
            width: 100%;
            z-index: 2;

            .event {
                position: absolute;
                display: flex;
                left: 0;
                width: calc(100% - 16px);
                margin: 0 8px;
                border: #FFF 1px solid;
                box-sizing: border-box;
                padding: 8px;
                font-size: 10px;
                border-radius: 6px;
                min-height: 30px;
                align-items: flex-start;
                justify-content: flex-start;
                color: #FFF;
                cursor: pointer;

                &.open{
                    background-color: #f2f2f2;
                    border-color: #fff;
                    color: #f2f2f2;

                    &:hover{
                        background-color: #666;
                        color: #fff;
                    }
                }

                &.busy{
                    background-color: $primary-dark;
                    color: #fff;

                    &:hover{
                        background-color: $primary;
                    }
                }

                &.past{
                    background-color: transparent;
                    border-color: transparent;
                    color: #CCC;
                    text-decoration: line-through;

                    &:hover{
                        background-color: #f2f2f2;
                        border-color: transparent;
                        color: #999;
                        
                    }
                }

                .event-time{
                    font-size: 12px;
                    font-weight: bold;
                    width: 40px;
                    display: block;
                }

                .event-title{
                    font-size: 12px;
                    font-weight: normal;
                    display: block;
                    width: calc(100% - 40px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                
            }

        }

        .now {
            position: absolute;
            width: calc(100% - 55px);
            height: 1px;
            background-color: $secondary;
            left: 55px;
            z-index: 3;

            span{
                position: absolute;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $secondary;
                top: -4.5px;
                left: 0;
            }
        }

    }

    .weekday-body-time{
        position: relative;
        display: block;
        width: 70px;
    }


}