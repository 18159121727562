.pagination{
    position: relative;
    display: flex;
    max-width: 600px;
    width: 100%;
    justify-content: center;
    margin: 0 auto;

    .results{
        width: 50%;
        text-align: center;
        

        .pages-desc{
            font-weight: bold;
            color: #2c2c2c;
        }

        .results-desc{
            color: #999;
        }
    }

    
}

@media only screen and (max-width: 767px) {
    .pagination{
        .results{
            display: none;
        }
        
    }
    
}